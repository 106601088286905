import React from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";

const AccountSettings = () => {
    return (
        <div>
            <Link to="/dashboard/change-password" className="btn btn-light">
                <i className="fas fa-lock text-primary" /> Change Password
            </Link>

        </div>
    )
};

const mapStateToProps = state => ({
    auth: state.auth
});


export default connect( mapStateToProps)(AccountSettings);
