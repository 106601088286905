import axios from 'axios';
import { setAlert } from './alert';
import {
  GET_TESTIMONIAL,
  GET_TESTIMONIALS,
  ADD_TESTIMONIAL,
  TESTIMONIAL_ERROR,
  EDIT_TESTIMONIAL,
  DELETE_TESTIMONIAL
} from './types';

// Get articles
export const getTestimonials = () => async dispatch => {
  try {
    const res = await axios.get('/api/testimonials');
    dispatch({
      type: GET_TESTIMONIALS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: TESTIMONIAL_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get testimonial by id
export const getTestimonialById = id => async dispatch => {
  try {
    const res = await axios.get(`/api/testimonials/${id}`);
    dispatch({
      type: GET_TESTIMONIAL,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: TESTIMONIAL_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Add testimonial
export const addTestimonial = formData => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  try {
    const res = await axios.post(`/api/testimonials`, formData, config);
    dispatch({
      type: ADD_TESTIMONIAL,
      payload: res.data
    });
    dispatch(setAlert('Testimonail Created', 'success'));
  } catch (err) {
    dispatch({
      type: TESTIMONIAL_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

export const addTestimonialImage = formData => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  try {
    const res = await axios.post(`/api/testimonials/image`, formData, config);
    dispatch(setAlert('Image added', 'success'));
  } catch (err) {
    dispatch({
      type: TESTIMONIAL_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

export const deleteTestimonial = id => async dispatch => {
  try {
    await axios.delete(`/api/testimonials/${id}`);
    dispatch({
      type: DELETE_TESTIMONIAL,
      payload: id
    });
    dispatch(setAlert('Testimonial Removed', 'success'));
  } catch (err) {
    dispatch({
      type: TESTIMONIAL_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

export const EditTestimonial = (formData, id) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  try {
    const res = await axios.patch(`/api/testimonials/${id}`, formData, config);
    dispatch({
      type: EDIT_TESTIMONIAL,
      payload: res.data
    });
    dispatch(setAlert('Testimonial Updated', 'success'));
  } catch (err) {
    dispatch({
      type: TESTIMONIAL_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
