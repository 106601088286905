import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import EditArticleForm from './EditArticleForm';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { getArticleById } from '../../actions/article';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(6),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  container: {
    padding: '1rem'
  },
  loadingContainer: {
    textAlign: 'center'
  }
}));
const EditArticle = ({
  getArticleById,
  match,
  article: { article, loading: articlesLoading }
}) => {
  const classes = useStyles();
  useEffect(() => {
    getArticleById(match.params.id);
  }, [getArticleById, match.params.id]);

  return (
    <Fragment>
      <Container maxWidth='lg' className={classes.container}>
        <Grid container spacing={3} alignItems='center' justify='center'>
          <Grid item xs={12} lg={12}>
            {!articlesLoading && article ? (
              <EditArticleForm article={article} articleId={match.params.id} />
            ) : (
              <div className={classes.loadingContainer}>
                <CircularProgress color='secondary' />
              </div>
            )}

            <Button style={{marginTop: '1rem'}}>
              <Link style={{ color: 'grey' }} to='/dashboard/view-articles'>
                Go Back
              </Link>
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

const mapStateToProps = state => ({ article: state.article });

export default connect(mapStateToProps, { getArticleById })(EditArticle);
