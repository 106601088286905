import {
  GET_TESTIMONIAL,
  GET_TESTIMONIALS,
  TESTIMONIAL_ERROR,
  ADD_TESTIMONIAL,
  EDIT_TESTIMONIAL
} from '../actions/types';
const initialState = {
  testimonials: [],
  testimonial: null,
  loading: true,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_TESTIMONIAL:
      return {
        ...state,
        testimonial: payload,
        loading: false
      };
    case GET_TESTIMONIALS:
      return {
        ...state,
        testimonials: payload,
        loading: false
      };
    case ADD_TESTIMONIAL:
      return {
        ...state,
        testimonials: [payload, ...state.testimonials],
        loading: false
      };
    case EDIT_TESTIMONIAL:
      return {
        ...state,
        testimonial: payload,
        loading: false
      };
    case TESTIMONIAL_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    default:
      return state;
  }
}
