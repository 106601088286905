import { combineReducers } from "redux";
import alert from "./alert";
import auth from "./auth";
import profile from "./profile";
import post from "./post";
import article from "./article";
import testimonial from "./testimonial";
import user from "./user";
export default combineReducers({
  alert,
  auth,
  post,
  profile,
  article,
  testimonial,
  user,
});
