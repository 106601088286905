import React, { Fragment,  useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import bannerImg from "../../assets/img/banner.jpg";
import contactImg from "../../assets/img/contact-us-background.jpg";
import theme from "../../components/layout/theme";
import Paper from "@material-ui/core/Paper";

import moment from "moment";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";

import Footer from "../layout/Footer";
import { getArticleBySlug } from "../../actions/article";
import PublicNavbar from "../layout/PublicNavbar";

const useStyles = makeStyles({
  bannerImg: {
    background: `url(${bannerImg}) bottom center`,
    backgroundPosition: "50% 50%",
    backgroundSize: "cover",
    backgroundColor: "#3498db",
    transform: "none",
    height: "100%",
    position: "absolute",
    width: "100%"
  },
  contactSection: {
    background: `url(${contactImg}) bottom center`,
    backgroundPosition: "50% 50%",
    backgroundSize: "cover",
    backgroundColor: "#3498db",
    transform: "none",
    height: "50%",
    position: "absolute",
    width: "100%",
    opacity: 0.4
  },
  banner: {
    height: "500px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      height: "400px"
    }
  },
  containerBanner: {
    paddingTop: "14rem",
    marginTop: "0px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "8rem"
    }
  },

  articleP: {
    color: "#88889b",
    fontSize: "15px",
    lineHeight: "28px",
    marginTop: "12px",
    whiteSpace: "pre-line"
  },
  articleDate: {
    color: "#88889b",
    fontSize: ".8rem",
    lineHeight: "28px"
  },
  bodyPFooter: {
    color: "white",
    fontSize: "15px",
    lineHeight: "28px",
    marginTop: "20px"
  },
  darkSection: {
    backgroundColor: "#35414c"
  },
  footer: {
    borderTop: "1px solid rgba(255, 255, 255, 0.15)"
  },
  contactFormContainer: {
    position: "absolute",
    top: "20%",
    zIndex: 99,
    width: "100%"
  },

  bannerZIndex: {
    zIndex: 99
  },
  bannerText: {
    fontSize: "50px",
    color: "#fff",
    marginBottom: "0px",
    lineHeight: "60px",
    fontWeight: 400,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "35px",
      lineHeight: "45px"
    }
  },
  paper: {
    padding: "50px",
    width: "100%",
    zIndex: 99,
    minHeight: "800px"
  },
  negativeMarginTop: {
    marginTop: "-400px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-300px"
    }
  }
});

const ViewArticle = ({
  match,
  getArticleBySlug,
  article: { article, loading }
}) => {
  const classes = useStyles();

  useEffect(() => {
    getArticleBySlug(match.params.slug);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <PublicNavbar />
      <section className={classes.banner}>
        <div className={classes.bannerImg}></div>
      </section>

      <section className={["container", classes.negativeMarginTop].join(" ")}>
        <div className="w-full flex justify-center mb-1">
          <Paper className={classes.paper}>
            <h3 className="mtext-center lg:text-3xl font-medium dark-blue-font mb-2">
              {article && article.title}
            </h3>
            <p></p>
            <p className={classes.articleDate}>
              {article && moment(article.date).format("MMMM Do YYYY")}
            </p>
            <p className={classes.articleP}>{article && article.text}</p>
          </Paper>
        </div>
      </section>
      <section className="container  mt-0-important">
        <Link to="/articles" style={{ color: "#88889b" }}>
          <ArrowLeftIcon />
          Go Back
        </Link>
      </section>
      <Footer />
    </Fragment>
  );
};

const mapStateToProps = state => ({
  article: state.article
});

export default connect(mapStateToProps, { getArticleBySlug })(ViewArticle);
