export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILES = "GET_PROFILES";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const GET_REPOS = "GET_REPOS";
export const GET_POSTS = "GET_POSTS";
export const POST_ERROR = "POST_ERROR";
export const UPDATE_LIKES = "UPDATE_LIKES";
export const DELETE_POST = "DELETE_POST";
export const ADD_POST = "ADD_POST";
export const GET_POST = "GET_POST";
export const ADD_COMMENT = "ADD_COMMENT";
export const REMOVE_COMMENT = "REMOVE_COMMENT";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
export const CLEAR_USER = "CLEAR_USER";

export const GET_ARTICLE = "GET_ARTICLE";
export const GET_ARTICLES = "GET_ARTICLES";
export const ARTICLE_ERROR = "ARTICLE_ERROR";
export const DELETE_ARTICLE = "DELETE_ARTICLE";
export const ADD_ARTICLE = "ADD_ARTICLE";
export const EDIT_ARTICLE = "EDIT_ARTICLE";

export const GET_TESTIMONIAL = "GET_TESTIMONIAL";
export const GET_TESTIMONIALS = "GET_TESTIMONIALS";
export const TESTIMONIAL_ERROR = "TESTIMONIAL_ERROR";
export const ADD_TESTIMONIAL = "ADD_TESTIMONIAL";
export const EDIT_TESTIMONIAL = "EDIT_TESTIMONIAL";
export const DELETE_TESTIMONIAL = "DELETE_TESTIMONIAL";

export const GET_USERS = "GET_USERS";
export const USER_ERROR = "USER_ERROR";
