import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../actions/auth";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import logo from "../../assets/img/logo/logo.svg";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
    marginTop: "0px",
    color: "black",
  },
  menuButtonWhite: {
    marginRight: theme.spacing(2),
    marginTop: "10px",
    color: "#fff",
  },
  title: {
    flexGrow: 1,
  },
  navLogo: {
    width: "175px",
    marginRight: "15px",
    verticalAlign: "middle",
    paddingTop: "0px",
  },
  appBar: {
    backgroundColor: "white",
    flexGrow: 1,
    display: "block",
    zIndex: 999,
    position: "fixed",
    top: 0,
    right: 0,
    left: 0,
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
  },
  appBarTransparent: {
    boxShadow: "none",
    backgroundColor: "transparent",
    flexGrow: 1,
    display: "block",
    zIndex: 999,
    position: "fixed",
    top: 0,
    right: 0,
    left: 0,
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
  },
  menuItem: {
    color: "#88889b",
  },
  menuItemTransparent: {
    color: "#88889b",
  },
}));

const Navbar = ({ auth: { loading, isAuthenticated, user }, logout }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  useScrollPosition(({ prevPos, currPos }) => {
    setScrollPosition(currPos.y);
  });

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleLogout() {
    logout();
  }

  const authLinks = (
    <Fragment>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <Link to="/dashboard">Dashboard</Link>
        </MenuItem>

        {user && user.admin ? (
          <MenuItem onClick={handleClose}>
            <Link to="/admin">Admin</Link>
          </MenuItem>
        ) : null}
        <MenuItem onClick={handleClose}>
          <Link to="/account-settings">Settings</Link>
        </MenuItem>
        <MenuItem onClick={() => handleLogout()}>Logout</MenuItem>
      </Menu>
    </Fragment>
  );

  const guestLinks = (
    <div className="flex flex-row">
      <div
        className={
          scrollPosition < -40
            ? "flex-row hidden lg:flex"
            : "flex-row hidden lg:flex"
        }
      >
        <div className="mx-3">
          <Link
            to="/"
            className={
              scrollPosition < -40
                ? classes.menuItem
                : classes.menuItemTransparent
            }
          >
            Home
          </Link>
        </div>
        <div className="mx-3">
          <Link
            to="/"
            className={
              scrollPosition < -40
                ? classes.menuItem
                : classes.menuItemTransparent
            }
          >
            Why Join
          </Link>
        </div>
        <div className="mx-3">
          <Link
            to="/"
            className={
              scrollPosition < -40
                ? classes.menuItem
                : classes.menuItemTransparent
            }
          >
            Our Team
          </Link>
        </div>
        <div className="mx-3">
          <Link
            to="/"
            className={
              scrollPosition < -40
                ? classes.menuItem
                : classes.menuItemTransparent
            }
          >
            Fee Structure
          </Link>
        </div>
        <div className="mx-3">
          <Link
            to="/"
            className={
              scrollPosition < -40
                ? classes.menuItem
                : classes.menuItemTransparent
            }
          >
            Testimonials
          </Link>
        </div>
        <div className="mx-3">
          <Link
            to="/articles"
            className={
              scrollPosition < -40
                ? classes.menuItem
                : classes.menuItemTransparent
            }
          >
            Articles
          </Link>
        </div>
        <div className="mx-3">
          <Link
            to="/contact"
            className={
              scrollPosition < -40
                ? classes.menuItem
                : classes.menuItemTransparent
            }
          >
            Contact
          </Link>
        </div>
        <div className="mx-3">
          <Link
            to="/login"
            className={
              scrollPosition < -40
                ? classes.menuItem
                : classes.menuItemTransparent
            }
          >
            Login
          </Link>
        </div>
      </div>
      <div className="lg:hidden flex">
        <IconButton
          edge="start"
          className={
            scrollPosition < -40 ? classes.menuButton : classes.menuButtonWhite
          }
          color="inherit"
          aria-label="menu"
          onClick={handleClick}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <MenuItem onClick={handleClose}>
            <Link style={{ color: "#88889b" }} to="/">
              Home
            </Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link style={{ color: "#88889b" }} to="/">
              Why Join
            </Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link style={{ color: "#88889b" }} to="/">
              Our Team
            </Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link style={{ color: "#88889b" }} to="/">
              Fee Structure
            </Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link style={{ color: "#88889b" }} to="/">
              Testimonials
            </Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link style={{ color: "#88889b" }} to="/articles">
              Articles
            </Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link style={{ color: "#88889b" }} to="/contact">
              Contact
            </Link>
          </MenuItem>
        </Menu>
      </div>
    </div>
  );

  return (
    <Fragment>
      <AppBar
        position="static"
        className={
          scrollPosition < -40 ? classes.appBar : classes.appBarTransparent
        }
      >
        <Toolbar className="container mt-0-important mb-0-important">
          <Link to="/" className="nav-title cursor-pointer">
            <img src={logo} className={classes.navLogo} alt="" />
          </Link>
          <div className={classes.title}></div>
          {!loading && (
            <Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>
          )}
        </Toolbar>
      </AppBar>
    </Fragment>
  );
};

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(Navbar);
