import React, {Fragment, useState} from "react";
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles, withStyles } from "@material-ui/core/styles";

import {changePassword} from '../../actions/auth';
import {connect} from "react-redux";
import { setAlert } from '../../actions/alert';
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import PropTypes from "prop-types";


const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    input: {
        display: 'none',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}));

const StyledInput = withStyles({
    root: {
      '& label.Mui-focused': {
        color: 'grey',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'grey',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'black',
        },
        '&:hover fieldset': {
          borderColor: 'black',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'grey',
        },
      },
    },
  })(TextField);

// TODO Dispatch success
const ChangePassword = ({setAlert,  changePassword }) => {
    const classes = useStyles();

    const [formData, setFormData] = useState({
        password: '',
        password2: ''
    });

    const { password, password2 } = formData;

    const onChange = e =>
        setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async e => {
        e.preventDefault();
        if (password !== password2) {
            setAlert('passwords do not match', 'danger');
        } else {
            setAlert('password saved', 'success');
            changePassword({ password });
            setFormData({...formData,  password: '',
                password2: '' })
        }
    };


    return (
        <Fragment>



            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Typography component="h1" variant="h5">
                        Change Password
                    </Typography>
                    <form className={classes.form} noValidate onSubmit={e => onSubmit(e)}>

                        <StyledInput
                            variant="outlined"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            minLength="6"
                            onChange={e => onChange(e)}
                        />

                        <StyledInput
                            variant="outlined"
                            required
                            fullWidth
                            name="password2"
                            label="Confirm Password"
                            type="password"
                            id="password2"
                            margin="normal"
                            value={password2}
                            onChange={e => onChange(e)}
                            autoComplete="confirm-password"
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Submit
                        </Button>
                    </form>
                </div>
            </Container>
        </Fragment>);
};

ChangePassword.propTypes = {
    changePassword: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired
};


export default connect(
    null ,
    { changePassword, setAlert }
)(ChangePassword);
