import React from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "../../routing/PrivateRoute";
import NotFound from "../../layout/NotFound";
import { default as ViewAllTestimonials } from "../../testimonials/ViewAll";
import { default as ViewAllArticles } from "../../articles/ViewAll";
import Testimonials from "../../testimonials/Testimonials";
import EditTestimonial from "../../testimonials/EditTestimonial";
import AddArticle from "../../articles/AddArticle";
import EditArticle from "../../articles/EditArticle";
import AccountSettings from "../AccountSettings";
import ChangePassword from "../../auth/ChangePassword";

import AddUser from "../admin/users/Add";
import EditUser from "../admin/userEdit";
import Landing from "../Landing";
import Admin from "../admin/admin";

import { connect } from "react-redux";

const Routes = ({ auth: { user } }) => {
  return (
    <Switch>
      <PrivateRoute
        path="/dashboard/view-testimonials"
        component={ViewAllTestimonials}
      />
      <PrivateRoute
        path="/dashboard/view-articles"
        component={ViewAllArticles}
      />
      <PrivateRoute exact path="/dashboard" component={Landing} />
      <PrivateRoute
        path="/dashboard/add-testimonial"
        component={Testimonials}
      />
      <PrivateRoute
        path="/dashboard/edit-testimonial/:id"
        component={EditTestimonial}
      />
      <PrivateRoute path="/dashboard/add-article" component={AddArticle} />
      <PrivateRoute
        path="/dashboard/edit-article/:id"
        component={EditArticle}
      />
      <PrivateRoute
        path="/dashboard/change-password"
        component={ChangePassword}
      />

      <PrivateRoute
        path="/dashboard/account-settings"
        component={AccountSettings}
      />
      <PrivateRoute
        path="/dashboard/account-settings"
        component={AccountSettings}
      />

      {user && user.admin && (
        <PrivateRoute exact path="/dashboard/admin" component={Admin} />
      )}
      {user && user.admin && (
        <PrivateRoute
          exact
          path="/dashboard/admin/user/create"
          component={AddUser}
        />
      )}
      {user && user.admin && (
        <PrivateRoute
          exact
          path="/dashboard/admin/user/edit/:userId"
          component={EditUser}
        />
      )}
      <Route component={NotFound} />
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Routes);
