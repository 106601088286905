import React, { Fragment, useState } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import bannerImg from "../../assets/img/banner.jpg";
import contactImg from "../../assets/img/contact-us-background.jpg";
import theme from "../../components/layout/theme";
import Paper from "@material-ui/core/Paper";
import Fade from "@material-ui/core/Fade";
import { setAlert } from "../../actions/alert";
import TextField from "@material-ui/core/TextField";
import Spinner from "../layout/Spinner";
import Footer from "../layout/Footer";
import PublicNavbar from "../layout/PublicNavbar";
import { sendEmail } from "../../actions/email";
import Alert from "../layout/Alert";

const useStyles = makeStyles({
  bannerImg: {
    background: `url(${bannerImg}) bottom center`,
    backgroundPosition: "50% 50%",
    backgroundSize: "cover",
    backgroundColor: "#3498db",
    transform: "none",
    height: "100%",
    position: "absolute",
    width: "100%",
  },
  contactSection: {
    background: `url(${contactImg}) bottom center`,
    backgroundPosition: "50% 50%",
    backgroundSize: "cover",
    backgroundColor: "#3498db",
    transform: "none",
    height: "50%",
    position: "absolute",
    width: "100%",
    bottom: "0",
    zIndex: "-1",
  },
  banner: {
    height: "500px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      height: "400px",
    },
  },
  containerBanner: {
    paddingTop: "12rem",
    marginTop: "0px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "8rem",
    },
  },

  bodyP: {
    color: "#88889b",
    fontSize: "15px",
    lineHeight: "28px",
    marginTop: "20px",
  },
  bodyPFooter: {
    color: "white",
    fontSize: "15px",
    lineHeight: "28px",
    marginTop: "20px",
  },
  darkSection: {
    backgroundColor: "#35414c",
  },
  footer: {
    position: "absolute",
    bottom: 0,
    width: "100%",
  },
  contactFormContainer: {
    position: "absolute",
    top: "20%",
    zIndex: 99,
    width: "100%",
  },
  paper: {
    marginTop: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 50,
    width: "500px",
    margin: "auto",
    [theme.breakpoints.down("xs")]: {
      left: "5%",
      width: "90%",
      padding: 25,
    },
  },
});

const StyledInput = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "grey",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "grey",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
      },
      "&:hover fieldset": {
        borderColor: "black",
      },
      "&.Mui-focused fieldset": {
        borderColor: "grey",
      },
    },
  },
})(TextField);

const Contact = ({ isAuthenticated, dispatch }) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
  });
  const { firstName, lastName, phone, email } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const [loading, setLoading] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    sendEmail(formData)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          dispatch(setAlert("Email Sent!", "success"));
          setFormData({
            firstName: "",
            lastName: "",
            phone: "",
            email: "",
          });
          setLoading(false);
          return;
        } else {
          dispatch(setAlert("Email not sent", "danger"));
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Fragment>
      <PublicNavbar />
      <Alert />

      <section className={classes.banner}>
        <div className={classes.bannerImg}></div>
      </section>
      <section className={classes.contactFormContainer}>
        <Paper className={classes.paper}>
          <h3 className="text-center md:text-left text-3xl font-medium dark-blue-font mb-8">
            Contact Us
          </h3>
          {/*prop for form: noValidate */}

          {loading ? (
            <Fade in={loading}>
              <Spinner />
            </Fade>
          ) : (
            <Fade in={!loading}>
              <form className={classes.form} onSubmit={(e) => onSubmit(e)}>
                <StyledInput
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  name="firstName"
                  type="text"
                  autoComplete="firstName"
                  value={firstName}
                  onChange={(e) => onChange(e)}
                  autoFocus
                />
                <StyledInput
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="lastName"
                  label="Last Name"
                  type="text"
                  id="lastName"
                  value={lastName}
                  onChange={(e) => onChange(e)}
                  autoComplete="current-password"
                />
                <StyledInput
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="phone"
                  label="Mobile Phone"
                  type="phone"
                  id="phone"
                  minLength="6"
                  value={phone}
                  onChange={(e) => onChange(e)}
                  autoComplete="phone"
                />
                <StyledInput
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="email"
                  label="Email"
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => onChange(e)}
                  autoComplete="email"
                />
                <p className="text-center text-xs opacity-50">
                  We'll never share your information with anyone else.
                </p>
                <button
                  type="submit"
                  className="w-full  bg-white hover:bg-gray-100 text-gray-800 py-4 px-24 border border-gray-400 rounded shadow mt-4"
                >
                  Submit
                </button>
              </form>
            </Fade>
          )}
        </Paper>
      </section>

      <section className={classes.contactSection}></section>

      <Footer />
    </Fragment>
  );
};

Contact.propTypes = {
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(Contact);
