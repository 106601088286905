import React, { Fragment, useEffect, useState } from "react";

import { connect } from "react-redux";
import EditForm from "./EditForm";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  container: {
    padding: "1rem",
  },
}));

const EditUser = ({ users: { users } }) => {
  const classes = useStyles();
  const [targetUser, setTargetUser] = useState();
  let { userId } = useParams();

  useEffect(() => {
    if (users && userId) {
      let target = users.filter((i) => i["_id"] === userId);
      if (target && target.length > 0) {
        setTargetUser(target[0]);
      }
    }
  }, [userId, users]);

  return (
    <Fragment>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3} alignItems="center" justify="center">
          <Grid item xs={12} lg={12}>
            <EditForm targetUser={targetUser} />
            <Button style={{ marginTop: "1rem" }}>
              <Link style={{ color: "grey" }} to="/dashboard/admin">
                Go Back
              </Link>
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  users: state.user,
});

export default connect(mapStateToProps)(EditUser);
