import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import StarIcon from "@material-ui/icons/Star";
import { connect } from "react-redux";
import { getTestimonials } from "../../actions/testimonial";
import theme from "../../components/layout/theme";

const useStyles = makeStyles({
  img: {
    height: "150px !important",
    width: "150px !important",
    borderRadius: "50%",
    marginRight: "25px"
  },
  slider: {
    width: "610px",
    backgroundColor: "white"
  },
  rating: {
    position: "absolute",
    bottom: "15px",
    left: "175px",
    [theme.breakpoints.up("md")]: {
      left: "70px"
    },
    textAlign: "center",
    backgroundColor: "#fff",
    padding: "5px 12px",
    borderRadius: "25px",
    boxShadow: "0 3px 15px 2px rgba(61, 61, 61, .12)"
  },
  icon_star: {
    fontSize: "16px",
    color: "#ffdc66"
  },
  avatarPhoto: {
    position: "relative",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      margin: "auto",
      width: "30%"
    }
  },
  testimonialP: {
    color: "#88889b",
    fontSize: "15px",
    lineHeight: "28px",
    marginTop: "0px"
  },
  slide: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row"
    }
  },
  slideTextContainer: {
    height: "100%",
    display: "flex",
    marginTop: "1rem",
    width: "100%",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      width: "70%",
      marginTop: "0rem"
    }
  }
});
const TestimonialSlider = ({
  testimonial: { testimonials, loading: testimonialsLoading },
  getTestimonials
}) => {
  const classes = useStyles();
  useEffect(() => {
    getTestimonials();
  }, []);

  return (
    <>
      {testimonials && testimonials.length > 0 && (
        <Carousel
          showThumbs={false}
          showArrows={false}
          showDots={false}
          showIndicators={false}
          showStatus={false}
          className={classes.slider}
          autoPlay
          interval={3500}
          transitionTime={1000}
          infiniteLoop
        >
          {testimonials.map((testimonial, i) => {
            return (
              <div className={classes.slide} key={i + testimonial.name}>
                <div className={classes.avatarPhoto}>
                  <img
                    src={testimonial.image_url}
                    className={classes.img}
                    alt=""
                  />
                  <div className={classes.rating}>
                    <span>
                      <StarIcon className={classes.icon_star} />
                    </span>
                    <span>
                      <StarIcon className={classes.icon_star} />
                    </span>
                    <span>
                      <StarIcon className={classes.icon_star} />
                    </span>
                    <span>
                      <StarIcon className={classes.icon_star} />
                    </span>
                    <span>
                      <StarIcon className={classes.icon_star} />
                    </span>
                  </div>
                </div>
                <div className={classes.slideTextContainer}>
                  <p className={[classes.testimonialP, "text-left "].join(" ")}>
                    "{testimonial.testimonial}"
                  </p>
                  <p className="text-left font-medium text-1xl dark-blue-font mt-2">
                    {testimonial.name}
                  </p>
                  <p className="text-left font-medium text-xs dark-blue-font ">
                    Real Estate Agent
                  </p>
                </div>
              </div>
            );
          })}
        </Carousel>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  testimonial: state.testimonial
});

export default connect(mapStateToProps, { getTestimonials })(TestimonialSlider);
