import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: theme.spacing(2),
        overflowX: 'auto',
    }
}));



const  UserTable = ({profiles}) => {
    const classes = useStyles();
    return (
        <Paper className={classes.root}>
            <Typography variant="h5" component="h3">
                Total Users: {profiles.length}
            </Typography>
        </Paper>
    );
}

export default  UserTable;

