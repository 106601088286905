import React, { Fragment } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";

import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import AccountCircle from "@material-ui/icons/AccountCircle";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCurrentProfile } from "../../actions/profile";
import { getArticles, deleteArticle } from "../../actions/article";
import { getTestimonials, deleteTestimonial } from "../../actions/testimonial";
import { Switch, Route } from "react-router-dom";
import RateReviewIcon from "@material-ui/icons/RateReview";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import SecurityIcon from "@material-ui/icons/Security";
import Routes from "./routing/Routes";
import { withRouter } from "react-router-dom";
import { logout } from "../../actions/auth";

import HomequestLogo from "../../assets/img/logo/logo.svg";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 15,
    marginLeft: 5,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const Dashboard = ({ auth: { user }, history, logout }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenuIcon = Boolean(anchorEl);

  function handleDrawerOpen() {
    setOpen(true);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  let handleMenuClick = (link) => {
    history.push(link);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.toolbar}>
          <div style={{ display: "flex" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              <MenuIcon />
            </IconButton>
            <Link to="/dashboard" style={{ textDecoration: "none" }}>
              <img src={HomequestLogo} style={{ width: "200px" }} />
            </Link>
          </div>
          <Fragment>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
              keepMounted
              open={openMenuIcon}
              onClose={handleClose}
            >
              {user && user.admin ? (
                <MenuItem onClick={handleClose}>
                  <Link
                    to="/dashboard/admin"
                    style={{ textDecoration: "none" }}
                  >
                    Admin
                  </Link>
                </MenuItem>
              ) : null}
              <MenuItem onClick={handleClose}>
                <Link
                  to="/dashboard/account-settings"
                  style={{ textDecoration: "none" }}
                >
                  Settings
                </Link>
              </MenuItem>
              <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
          </Fragment>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        open={open}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {user && user.admin && (
            <ListItem
              button
              onClick={() => handleMenuClick("/dashboard/admin")}
            >
              <ListItemIcon style={{ marginLeft: "10px" }}>
                <SecurityIcon />
              </ListItemIcon>
              <ListItemText primary={"Admin"} />
            </ListItem>
          )}
          <ListItem
            button
            onClick={() => handleMenuClick("/dashboard/view-articles")}
          >
            <ListItemIcon style={{ marginLeft: "10px" }}>
              <LibraryBooksIcon />
            </ListItemIcon>
            <ListItemText primary={"Articles"} />
          </ListItem>
          <ListItem
            button
            onClick={() => handleMenuClick("/dashboard/view-testimonials")}
          >
            <ListItemIcon style={{ marginLeft: "10px" }}>
              <RateReviewIcon />
            </ListItemIcon>
            <ListItemText primary={"Testimonials"} />
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <Switch>
          <Route component={Routes} />
        </Switch>
      </main>
    </div>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
  article: state.article,
  testimonial: state.testimonial,
  deleteAccount: PropTypes.func.isRequired,
});

export default withRouter(
  connect(mapStateToProps, {
    getCurrentProfile,
    getArticles,
    getTestimonials,
    deleteTestimonial,
    deleteArticle,
    logout,
  })(Dashboard)
);
