import React, { useState } from 'react';

import { connect } from 'react-redux';
import { addTestimonial, addTestimonialImage } from '../../actions/testimonial';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { withRouter } from 'react-router-dom';


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(6),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  container: {
    padding: '1rem'
  },
  form: {
    display: 'flex',
    flexDirection: 'column'
  },
  mb2: {
    marginBottom: '2rem'
  }
}));

const TestimonialForm = ({
  addTestimonial,
  addTestimonialImage,
  formStep,
  setFormStep,
  history
}) => {
  const [testimonial, setTestimonial] = useState('');
  const [name, setName] = useState('');
  const [agentImage, setAgentImage] = useState();

  const [file, setFile] = useState();
  const [fileLoading, setFileLoading] = useState(false);

  const classes = useStyles();

  let onChange = e => {
    setFile(e.target.files[0]);
  };

  let onFormSubmit = e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('image', file);
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    axios
      .post('/api/testimonials/image', formData, config)
      .then(response => {
        setAgentImage(response.data);
        setFormStep(2);
      })
      .catch(error => {});
  };

  return (
    <Paper className={classes.paper}>
      <form onSubmit={e => onFormSubmit(e)} className={classes.form} style={{marginBottom: '2rem'}}>
          <Button variant='contained' component='label' className={classes.mb2}>
            Select Agent Image
            <input
              type='file'
              style={{ display: 'none' }}
              onChange={e => onChange(e)}
            />
          </Button>
          {file && (
            <Button disabled={!file} type='submit'>
            Save Image
          </Button>
          )}
          
        </form>
      <form
          onSubmit={e => {
            e.preventDefault();

            if (agentImage) {
              let image_url = agentImage.url;
              let image_id = agentImage.id;
              addTestimonial({ testimonial, name, image_url, image_id });
            } else {
              addTestimonial({ testimonial, name });
            }

            setTestimonial('');
            setName('');
            setAgentImage();
            setFormStep(1);
            history.push('/dashboard');
          }}
          className={classes.form}
        >
          <TextField
            name='text'
            cols='30'
            rows='5'
            placeholder='Name'
            value={name}
            onChange={e => setName(e.target.value)}
            required
            variant='outlined'
            className={classes.mb2}
          />

          <TextField
            name='text'
            cols='30'
            rows='5'
            placeholder='Testimonial'
            value={testimonial}
            multiline
            onChange={e => setTestimonial(e.target.value)}
            required
            variant='outlined'
            className={classes.mb2}
          />

          <Button type='submit' variant='outlined'>
            Save
          </Button>
        </form>
    </Paper>
  );
};


export default withRouter(
  connect(null, { addTestimonial, addTestimonialImage })(TestimonialForm)
);
