import React, { Fragment, useEffect } from "react";
import { Link,  withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import bannerImg from "../../assets/img/banner.jpg";
import contactImg from "../../assets/img/contact-us-background.jpg";
import theme from "../../components/layout/theme";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import Footer from "../layout/Footer";
import { getArticles } from "../../actions/article";
import PublicNavbar from "../layout/PublicNavbar";

const useStyles = makeStyles({
  bannerImg: {
    background: `url(${bannerImg}) bottom center`,
    backgroundPosition: "50% 50%",
    backgroundSize: "cover",
    backgroundColor: "#3498db",
    transform: "none",
    height: "100%",
    position: "absolute",
    width: "100%"
  },
  contactSection: {
    background: `url(${contactImg}) bottom center`,
    backgroundPosition: "50% 50%",
    backgroundSize: "cover",
    backgroundColor: "#3498db",
    transform: "none",
    height: "50%",
    position: "absolute",
    width: "100%",
    opacity: 0.4
  },
  banner: {
    height: "500px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      height: "400px"
    }
  },
  containerBanner: {
    paddingTop: "8rem",
    marginTop: "0px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "8rem"
    }
  },

  articleP: {
    color: "#88889b",
    fontSize: "15px",
    lineHeight: "28px",
    marginTop: "12px"
  },
  articleDate: {
    color: "#88889b",
    fontSize: ".8rem",
    lineHeight: "28px"
  },
  bodyPFooter: {
    color: "white",
    fontSize: "15px",
    lineHeight: "28px",
    marginTop: "20px"
  },
  darkSection: {
    backgroundColor: "#35414c"
  },
  footer: {
    borderTop: "1px solid rgba(255, 255, 255, 0.15)"
  },
  contactFormContainer: {
    position: "absolute",
    top: "20%",
    zIndex: 99,
    width: "100%"
  },
  paper: {
    padding: 20,
    width: "100%"
  },
  bannerZIndex: {
    zIndex: 99
  },
  bannerText: {
    fontSize: "50px",
    color: "#fff",
    marginBottom: "0px",
    lineHeight: "60px",
    fontWeight: 400,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "35px",
      lineHeight: "45px",
      textAlign: "left"
    }
  },
  bannerButton: {
    width: "100%",
    color: "white",
    "&:hover": {
      color: "#4b456f"
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "20rem"
    }
  }
});

const Articles = ({ getArticles, article: { articles, loading }, history }) => {
  const classes = useStyles();
  useEffect(() => {
    getArticles();
  }, [getArticles]);

  let handleLearnMoreClick = () => {
    history.push("/contact");
  };

  return (
    <Fragment>
      <PublicNavbar />
      <section className={classes.banner}>
        <div className={classes.bannerImg}></div>
        <div
          className={["container mx-auto", classes.containerBanner].join(" ")}
        >
          <div className="flex mb-4">
            <div
              className={[
                "w-full justify-center items-center flex-col flex",
                classes.bannerZIndex
              ].join(" ")}
            >
              <h1 className={classes.bannerText}>
                Keep up to date with <br /> the latest Real Estate <br /> News
                Articles
              </h1>
              <button
                onClick={() => handleLearnMoreClick()}
                className={[
                  "w-500px mt-8   hover:bg-white   py-3 px-2 border border-gray-400 rounded shadow",
                  classes.bannerButton
                ].join(" ")}
              >
                Learn More
              </button>
            </div>
          </div>
        </div>
      </section>

      <div>
      
        {articles.map(article => {
          return (
            <section key={article._id} className="container mt-3-important">
              <div className="w-full flex justify-center mb-5">
                <Paper className={classes.paper}>
                  <h3 className=" text-xl ld:text-3xl font-medium dark-blue-font mb-2">
                    {article.title}
                  </h3>
                  <p className={classes.articleDate}>
                    {moment(article.date).format("MMMM Do YYYY")}
                  </p>
                  <p className={classes.articleP}>{article.snippet}</p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      marginTop: "24px",
                      cursor: "pointer"
                    }}
                  >
                    <Link to={`/articles/${article.slug}`}>Read More</Link>
                  </div>
                </Paper>
              </div>
            </section>
          );
        })}
      </div>

      <Footer />
    </Fragment>
  );
};

const mapStateToProps = state => ({
  article: state.article
});

export default withRouter(connect(mapStateToProps, { getArticles })(Articles));
