import React, { useState } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../../assets/img/logo/logo.svg";
import logoWhite from "../../assets/img/logo/logo-white.svg";
import { Link, withRouter } from "react-router-dom";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import "bootstrap/dist/css/bootstrap.min.css";
import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
    marginTop: "0px",
    color: "black",
  },
  menuButtonWhite: {
    marginRight: theme.spacing(2),
    marginTop: "10px",
    color: "#fff",
  },
  title: {
    flexGrow: 1,
  },
  navLogo: {
    width: "175px",
    marginRight: "15px",
    verticalAlign: "middle",
    paddingTop: "0px",
  },
  appBar: {
    background: "white !important",
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
  },
  appBarTransparent: {
    background: "transparent !important",
  },
  collapseMenu: {
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "white",
    },
  },
  menuItem: {
    color: "#88889b !important",

    [theme.breakpoints.down("md")]: {
      marginTop: "5px  !important",
      marginBottom: "5px  !important",
      textAlign: "left",
      paddingLeft: "1em !important",
    },
  },
  menuItemTransparent: {
    color: "white !important",
    [theme.breakpoints.down("sm")]: {
      color: "grey !important",
      marginTop: "5px !important",
      marginBottom: "5px  !important",
      textAlign: "left",
      paddingLeft: "1em !important",
    },
  },
  menuItemButton: {
    color: "#88889b !important",
    // border: "1px solid #88889b",
    // borderRadius: "5px",
    [theme.breakpoints.down("md")]: {
      marginTop: "5px  !important",
      marginBottom: "5px  !important",
      textAlign: "left",
      paddingLeft: "1em !important",
    },
  },
  menuItemTransparentButton: {
    color: "white !important",
    // border: "1px solid white",
    // borderRadius: "5px",
    [theme.breakpoints.down("sm")]: {
      color: "grey !important",
      marginTop: "5px !important",
      marginBottom: "5px  !important",
      textAlign: "left",
      paddingLeft: "1em !important",
    },
  },
  menuButtonWhite: {
    borderColor: "rgb(255, 255, 255) !important",
    marginRight: "16px",
  },
  greyMenuIcon: {
    color: "grey",
  },
  whiteMenuIcon: {
    color: "white",
  },
}));

let PublicNavbar = ({
  executeHomeScroll,
  executeJoinScroll,
  executeOurteamScroll,
  executeFeeStructureScroll,
  executeTestimonialScroll,
  history,
}) => {
  const classes = useStyles();

  const [scrollPosition, setScrollPosition] = useState(0);
  const [expanded, setExpanded] = useState(false);

  useScrollPosition(({ prevPos, currPos }) => {
    setScrollPosition(currPos.y);
  });

  let handleNav = (func) => {
    if (history.location.pathname !== "/") {
      history.push("/");
    } else {
      func();
    }
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg="dark"
      className={
        scrollPosition < -40 ? classes.appBar : classes.appBarTransparent
      }
      style={{ borderBottom: "none", zIndex: 999, opacity: 1 }}
      expanded={expanded}
    >
      <Container style={{ marginTop: "0px", marginBottom: "0px" }}>
        <Navbar.Brand style={{ padding: "0px" }}>
          <Link to="/" style={{ width: "100%", display: "block" }}>
            <img
              src={scrollPosition < -40 ? logo : logoWhite}
              className={classes.navLogo}
              alt=""
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          className={
            scrollPosition < -40 ? classes.menuButton : classes.menuButtonWhite
          }
          onClick={() => setExpanded(!expanded)}
          children={
            <MenuIcon
              className={
                scrollPosition < -40
                  ? classes.greyMenuIcon
                  : classes.whiteMenuIcon
              }
            />
          }
        />

        <Navbar.Collapse
          id="responsive-navbar-nav"
          className={classes.collapseMenu}
        >
          <Nav className="mr-auto"></Nav>

          <Nav>
            <Nav.Link
              onClick={() => {
                handleNav(executeHomeScroll);
                setExpanded(false);
                return;
              }}
              className={
                scrollPosition < -40
                  ? classes.menuItem
                  : classes.menuItemTransparent
              }
            >
              Home
            </Nav.Link>
            <Nav.Link
              onClick={() => {
                handleNav(executeJoinScroll);
                setExpanded(false);
                return;
              }}
              className={
                scrollPosition < -40
                  ? classes.menuItem
                  : classes.menuItemTransparent
              }
            >
              Why Join
            </Nav.Link>
            <Nav.Link
              onClick={() => {
                handleNav(executeOurteamScroll);
                setExpanded(false);
                return;
              }}
              className={
                scrollPosition < -40
                  ? classes.menuItem
                  : classes.menuItemTransparent
              }
            >
              Our Team
            </Nav.Link>
            <Nav.Link
              onClick={() => {
                handleNav(executeFeeStructureScroll);
                setExpanded(false);
                return;
              }}
              className={
                scrollPosition < -40
                  ? classes.menuItem
                  : classes.menuItemTransparent
              }
            >
              Fee Structure
            </Nav.Link>
            <Nav.Link
              className={
                scrollPosition < -40
                  ? classes.menuItem
                  : classes.menuItemTransparent
              }
              onClick={() => {
                handleNav(executeTestimonialScroll);
                setExpanded(false);
                return;
              }}
            >
              Testimonials
            </Nav.Link>
            <Nav.Link
              className={
                scrollPosition < -40
                  ? classes.menuItem
                  : classes.menuItemTransparent
              }
              href="/articles"
            >
              Articles
            </Nav.Link>
            <Nav.Link
              className={
                scrollPosition < -40
                  ? classes.menuItem
                  : classes.menuItemTransparent
              }
              href="/contact"
            >
              Contact
            </Nav.Link>
            <Nav.Link
              className={
                scrollPosition < -40
                  ? classes.menuItemButton
                  : classes.menuItemTransparentButton
              }
              href="/login"
            >
              Login
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default withRouter(PublicNavbar);
