import React, { Fragment, useState, useRef } from "react";
import { withRouter } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import bannerImg from "../../assets/img/banner.jpg";
import theme from "../../components/layout/theme";

import Typography from "@material-ui/core/Typography";

import Collapse from "@material-ui/core/Collapse";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Link } from "react-router-dom";

import TestimonialSlider from "../home/TestimonialSlider";

import brokerIcon from "../../assets/img/icons/broker-support.svg";
import homeIcon from "../../assets/img/icons/home.svg";
import marketingIcon from "../../assets/img/icons/real-estate-marketing.svg";
import trainingIcon from "../../assets/img/icons/real-estate-training.svg";
import commissionIcon from "../../assets/img/icons/100-commission-real-estate-broker.svg";
import realtorIcon from "../../assets/img/icons/realtor-app.svg";
import eAndOIcon from "../../assets/img/icons/real-estate-e-and-o-insurance.svg";
import partTimeAgentsIcon from "../../assets/img/icons/part-time-real-estate-agent-broker.svg";
import flatFeeBrokerIcon from "../../assets/img/icons/flat-fee-real-estate-broker.svg";
import noMonthlyFeeBrokerIcon from "../../assets/img/icons/no-monthly-fee-broker.svg";
import realEstateEscrowIcon from "../../assets/img/icons/real-estate-escrow.svg";
import realEstateBrokerIcon from "../../assets/img/icons/real-estate-broker.svg";
import realEstateAssociationsIcon from "../../assets/img/icons/real-estate-associations.svg";
import realEstateTechnologyIcon from "../../assets/img/icons/real-estate-agent-technology.svg";
import shortSaleProcessorIcon from "../../assets/img/icons/short-sale-processor.svg";
import transactionCoordinatorIcon from "../../assets/img/icons/transaction-coordinator.svg";

import aboutOurCompanyImage from "../../assets/img/real-estate-brokerage.jpg";
import flatFeeRealEstateBroker from "../../assets/img/flat-fee-real-estate-broker.jpg";

import quote from "../../assets/img/testimonials/quote.png";

import Footer from "../layout/Footer";

import PublicNavbar from "./PublicNavbar";

const useStyles = makeStyles({
  bannerImg: {
    background: `url(${bannerImg}) bottom center`,
    backgroundPosition: "50% 50%",
    backgroundSize: "cover",
    backgroundColor: "#3498db",
    transform: "none",
    height: "100%",
    position: "absolute",
    width: "100%",
  },
  banner: {
    height: "500px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      height: "450px",
    },
  },
  containerBanner: {
    paddingTop: "12rem",
    marginTop: "0px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "8rem",
    },
  },
  bannerZIndex: {
    zIndex: 99,
  },
  bannerText: {
    fontSize: "50px",
    color: "#fff",
    marginBottom: "0px",
    lineHeight: "60px",
    fontWeight: 400,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "35px",
      lineHeight: "45px",
      textAlign: "left",
    },
  },
  bannerPitch: {
    color: "white",
    textAlign: "center",
    marginTop: ".5em",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      width: "100%",
    },
  },
  bodyP: {
    color: "#88889b",
    fontSize: "15px",
    lineHeight: "28px",
    marginTop: "20px",
  },
  bodyPFooter: {
    color: "white",
    fontSize: "15px",
    lineHeight: "28px",
    marginTop: "20px",
  },
  brokerIcon: {
    width: "60px",
    height: "60px",
  },
  featureTitle: {},
  featureParagraph: {
    marginTop: "6px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  sectionColor: {
    backgroundColor: "#F7F7FB",
  },
  aboutCompanyImage: {
    maxWidth: "520px",
    maxHeight: "346.5px",
    marginRight: "auto",
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      marginTop: "24px",
    },
  },
  aboutSectionP: {
    maxWidth: "520px",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
    },
  },
  tabSection: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: "2rem",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "520px",
      margin: "auto",
      marginTop: "2rem",
    },
  },
  noShadow: {
    boxShadow: "none",
  },
  indicator: {
    backgroundColor: "#ea232a",
  },
  tableRoot: {
    width: "100%",
    overflowX: "auto",
  },
  table: {},
  quote: {
    width: "120px",
    float: "right",
    position: "absolute",
    right: "40px",
    top: "0px",
    zIndex: "-3px",
    [theme.breakpoints.down("sm")]: {
      right: "0px",
      width: "80px",
    },
  },
  parent: {
    position: "relative",
  },
  overflowShow: {
    overflow: "visible",
  },
  darkSection: {
    backgroundColor: "#35414c",
  },
  footer: {
    borderTop: "1px solid rgba(255, 255, 255, 0.15)",
  },
  tab: {
    [theme.breakpoints.down("sm")]: {
      fontSize: ".6rem",
    },
  },
  bannerButton: {
    width: "100%",
    color: "white",
    "&:hover": {
      color: "#4b456f",
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "20rem",
    },
  },
  tableCell: {
    color: "#88889b",
    fontSize: "15px",
    lineHeight: "28px",
    paddingLeft: "0px",
    [theme.breakpoints.up("md")]: {
      fontSize: ".9rem",
      paddingLeft: "14px",
    },
  },
  tableHead: {
    color: "#4b456f",
    fontSize: "1rem",
    paddingLeft: "0px",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "14px",
    },
  },
  collapseWidth: {
    width: "100%",
  },
});

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

const Landing = ({ history }) => {
  const classes = useStyles();
  const [viewMore, setViewMore] = useState(false);
  const [showFees, setShowFees] = useState(false);
  const [tabValue, setTabValue] = React.useState(0);

  const homeRef = useRef(null);
  const executeHomeScroll = () => scrollToRef(homeRef);

  const joinRef = useRef(null);
  const executeJoinScroll = () => scrollToRef(joinRef);

  const ourTeamRef = useRef(null);
  const executeOurteamScroll = () => scrollToRef(ourTeamRef);

  const feeStructureRef = useRef(null);
  const executeFeeStructureScroll = () => scrollToRef(feeStructureRef);

  const testimonialRef = useRef(null);
  const executeTestimonialScroll = () => scrollToRef(testimonialRef);

  const handleTabChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  let handleLearnMoreClick = () => {
    history.push("/contact");
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={0}>{children}</Box>}
      </Typography>
    );
  }

  return (
    <Fragment>
      <PublicNavbar
        executeHomeScroll={executeHomeScroll}
        executeJoinScroll={executeJoinScroll}
        executeOurteamScroll={executeOurteamScroll}
        executeFeeStructureScroll={executeFeeStructureScroll}
        executeTestimonialScroll={executeTestimonialScroll}
      />
      <section className={classes.banner} ref={homeRef}>
        <div className={classes.bannerImg}></div>
        <div
          className={["container mx-auto", classes.containerBanner].join(" ")}
        >
          <div className="flex mb-4">
            <div
              className={[
                "w-full justify-center items-center flex-col flex",
                classes.bannerZIndex,
              ].join(" ")}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <h1 className={classes.bannerText}>
                  Earn 100% Commission... <br /> No Monthly Fee!
                </h1>
                <h3 className={classes.bannerPitch}>
                  Starting at only $475 per residential transaction*
                </h3>
              </div>
              <button
                onClick={() => handleLearnMoreClick()}
                className={[
                  "w-500px mt-8   hover:bg-white   py-3 px-2 border border-gray-400 rounded shadow",
                  classes.bannerButton,
                ].join(" ")}
              >
                Learn More
              </button>
            </div>
          </div>
        </div>
      </section>
      <div
        ref={joinRef}
        style={{ marginTop: "-50px", paddingBottom: "50px" }}
      />
      <section className="container mt-3-important">
        <div className="flex content-center flex-wrap ">
          <div className="w-full ">
            <h2 className="text-center text-xl md:text-3xl font-medium dark-blue-font">
              Join The Future of Real Estate
            </h2>
            <p
              className={["lg:px-40 mt-4 md:text-center", classes.bodyP].join(
                " "
              )}
            >
              Homequest is paving the way for Real Estate Agent's to earn more
              money! We believe successful agents start with a strong
              foundation. Earn more money and gain financial freedom to invest
              more into your marketing while receiving exceptional customer
              service, broker support, and training that is needed for your
              business to thrive.
            </p>
          </div>
        </div>
      </section>
      <section className="container mt-0 md:mt-3-important ">
        <div className="flex md:mb-4 flex-wrap">
          <div className="w-full md:w-1/2 ">
            <div className="flex mb-12 md:mb-4">
              <div className="w-1/6 ">
                <img src={brokerIcon} className={classes.brokerIcon} alt="" />
              </div>
              <div className="w-5/6 ">
                <h3
                  className={[
                    classes.featureTitle,
                    "md:text-lg pl-3 text-xs md:pl-0 font-medium",
                  ].join(" ")}
                >
                  FULL BROKER SUPPORT
                </h3>
                <p
                  className={[
                    classes.featureParagraph,
                    classes.bodyP,
                    "pl-3 md:pl-0 md:pr-4",
                  ].join(" ")}
                >
                  Homequest agents are welcome to call or come into our office
                  for support.
                </p>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2">
            <div className="flex ">
              <div className="w-1/6">
                <img src={homeIcon} className={classes.brokerIcon} alt="" />
              </div>
              <div className="w-5/6 ">
                <h3
                  className={[
                    classes.featureTitle,
                    "md:text-lg pl-3 text-xs md:pl-0 font-medium",
                  ].join(" ")}
                >
                  FULL BROKER SUPPORT
                </h3>
                <p
                  className={[
                    classes.featureParagraph,
                    classes.bodyP,
                    "pl-3 md:pl-0",
                  ].join(" ")}
                >
                  Homequest agents are welcome to call or come into our office
                  for support.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container mt-0 md:mt-3-important">
        <div className="flex  md:mb-4 flex-wrap">
          <div className="w-full md:w-1/2 ">
            <div className="flex mb-12 md:mb-4">
              <div className="w-1/6 ">
                <img
                  src={marketingIcon}
                  className={classes.brokerIcon}
                  alt=""
                />
              </div>
              <div className="w-5/6 ">
                <h3
                  className={[
                    classes.featureTitle,
                    "md:text-lg pl-3 text-xs font-medium  md:pl-0 ",
                  ].join(" ")}
                >
                  MARKETING RESOURCES
                </h3>
                <p
                  className={[
                    classes.featureParagraph,
                    classes.bodyP,
                    "md:pr-4 pl-3 md:pl-0",
                  ].join(" ")}
                >
                  Homequest Agents receive free marketing tools that help Grow
                  your business.
                </p>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2">
            <div className="flex  ">
              <div className="w-1/6 ">
                <img src={trainingIcon} className={classes.brokerIcon} alt="" />
              </div>
              <div className="w-5/6 ">
                <h3
                  className={[
                    classes.featureTitle,
                    "md:text-lg pl-3 text-xs  font-medium md:pl-0 ",
                  ].join(" ")}
                >
                  MONTHLY TRAINING
                </h3>
                <p
                  className={[
                    classes.featureParagraph,
                    classes.bodyP,
                    " pl-3 md:pl-0",
                  ].join(" ")}
                >
                  Continuing your education is important to us. We provide
                  important training in cutting-edge tips &amp; tools.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container mt-0 md:mt-3-important">
        <div className="flex   md:mb-4 flex-wrap">
          <div className="w-full md:w-1/2 ">
            <div className="flex mb-12 md:mb-4">
              <div className="w-1/6 ">
                <img
                  src={commissionIcon}
                  className={classes.brokerIcon}
                  alt=""
                />
              </div>
              <div className="w-5/6 ">
                <h3
                  className={[
                    classes.featureTitle,
                    "md:text-lg pl-3 text-xs  font-medium   md:pl-0 ",
                  ].join(" ")}
                >
                  NEGOTIATE YOUR OWN COMMISSION
                </h3>
                <p
                  className={[
                    classes.featureParagraph,
                    classes.bodyP,
                    "md:pr-4 pl-3 md:pl-0",
                  ].join(" ")}
                >
                  Never lose a sale or listing over any company regulated
                  commission.
                </p>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2">
            <div className="flex">
              <div className="w-1/6 ">
                <img src={realtorIcon} className={classes.brokerIcon} alt="" />
              </div>
              <div className="w-5/6 ">
                <h3
                  className={[
                    classes.featureTitle,
                    "md:text-lg pl-3 text-xs font-medium   md:pl-0 ",
                  ].join(" ")}
                >
                  MOBILE APP FOR IPHONE / ANDROID
                </h3>
                <p
                  className={[
                    classes.featureParagraph,
                    classes.bodyP,
                    " pl-3 md:pl-0",
                  ].join(" ")}
                >
                  We provide you with a custom lead generating iPhone &amp;
                  Android application.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Collapse in={viewMore}>
        <section className="container mt-0-important">
          <div className="flex md:mb-4 flex-wrap">
            <div className="w-full md:w-1/2 ">
              <div className="flex  mb-12 md:mb-4">
                <div className="w-1/6 ">
                  <img src={eAndOIcon} className={classes.brokerIcon} alt="" />
                </div>
                <div className="w-5/6 ">
                  <h3
                    className={[
                      classes.featureTitle,
                      "md:text-lg pl-3 text-xs  font-medium md:pl-0",
                    ].join(" ")}
                  >
                    E&amp;O INSURANCE
                  </h3>
                  <p
                    className={[
                      classes.featureParagraph,
                      classes.bodyP,
                      "md:pr-4  pl-3 md:pl-0",
                    ].join(" ")}
                  >
                    We provide a low cost E&amp;O insurance premium on every
                    transaction for your protection.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2">
              <div className="flex ">
                <div className="w-1/6">
                  <img
                    src={partTimeAgentsIcon}
                    className={classes.brokerIcon}
                    alt=""
                  />
                </div>
                <div className="w-5/6 ">
                  <h3
                    className={[
                      classes.featureTitle,
                      "md:text-lg pl-3 text-xs  font-medium   md:pl-0",
                    ].join(" ")}
                  >
                    PART TIME AGENTS WELCOME
                  </h3>
                  <p
                    className={[
                      classes.featureParagraph,
                      classes.bodyP,
                      "pl-3 md:pl-0",
                    ].join(" ")}
                  >
                    Part time agents are more than welcome here. we offer no
                    sales quota.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="container mt-0 md:mt-3-important">
          <div className="flex md:mb-4 flex-wrap">
            <div className="w-full md:w-1/2 ">
              <div className="flex  mb-12 md:mb-4">
                <div className="w-1/6">
                  <img
                    src={flatFeeBrokerIcon}
                    className={classes.brokerIcon}
                    alt=""
                  />
                </div>
                <div className="w-5/6">
                  <h3
                    className={[
                      classes.featureTitle,
                      "md:text-lg pl-3 text-xs  font-medium  md:pl-0",
                    ].join(" ")}
                  >
                    COMPETITIVE FEE STRUCTURE
                  </h3>
                  <p
                    className={[
                      classes.featureParagraph,
                      classes.bodyP,
                      "md:pr-4 pl-3 md:pl-0",
                    ].join(" ")}
                  >
                    Our flat fee commission structure will help you save money
                    which can help you grow your business.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2">
              <div className="flex ">
                <div className="w-1/6">
                  <img
                    src={noMonthlyFeeBrokerIcon}
                    className={classes.brokerIcon}
                    alt=""
                  />
                </div>
                <div className="w-5/6 ">
                  <h3
                    className={[
                      classes.featureTitle,
                      "md:text-lg pl-3 text-xs  font-medium  pl-3 md:pl-0",
                    ].join(" ")}
                  >
                    NO MONTHLY FEE
                  </h3>
                  <p
                    className={[
                      classes.featureParagraph,
                      classes.bodyP,
                      "pl-3 md:pl-0",
                    ].join(" ")}
                  >
                    As a Homequest Real Estate Agent you will receive industry
                    leading training.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container mt-0 md:mt-3-important">
          <div className="flex md:mb-4 flex-wrap">
            <div className="w-full md:w-1/2 ">
              <div className="flex  mb-12 md:mb-4">
                <div className="w-1/6 ">
                  <img
                    src={realEstateEscrowIcon}
                    className={classes.brokerIcon}
                    alt=""
                  />
                </div>
                <div className="w-5/6 ">
                  <h3
                    className={[
                      classes.featureTitle,
                      "md:text-lg pl-3 text-xs font-medium pl-3 md:pl-0",
                    ].join(" ")}
                  >
                    IN-HOUSE ESCROW
                  </h3>
                  <p
                    className={[
                      classes.featureParagraph,
                      classes.bodyP,
                      "md:pr-4 pl-3 md:pl-0",
                    ].join(" ")}
                  >
                    Homequest offers a In-House Escrow for your convenience and
                    improved processing time.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2">
              <div className="flex ">
                <div className="w-1/6 ">
                  <img
                    src={realEstateBrokerIcon}
                    className={classes.brokerIcon}
                    alt=""
                  />
                </div>
                <div className="w-5/6 ">
                  <h3
                    className={[
                      classes.featureTitle,
                      "md:text-lg pl-3 text-xs  font-medium pl-3 md:pl-0",
                    ].join(" ")}
                  >
                    GET PAID WITHIN ONE BUSINESS DAY
                  </h3>
                  <p
                    className={[
                      classes.featureParagraph,
                      classes.bodyP,
                      " pl-3 md:pl-0",
                    ].join(" ")}
                  >
                    Homequest agents get paid within one business day of
                    receiving your escrow closing package, provided that your
                    file is complete..
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container mt-0 md:mt-3-important">
          <div className="flex md:mb-4 flex-wrap">
            <div className="w-full md:w-1/2 ">
              <div className="flex  mb-12 md:mb-4">
                <div className="w-1/6">
                  <img
                    src={realEstateAssociationsIcon}
                    className={classes.brokerIcon}
                    alt=""
                  />
                </div>
                <div className="w-5/6 ">
                  <h3
                    className={[
                      classes.featureTitle,
                      "md:text-lg pl-3 text-xs  font-medium  pl-3 md:pl-0",
                    ].join(" ")}
                  >
                    MEMBER OF ALL MAJOR REAL ESTATE ASSOCIATIONS
                  </h3>
                  <p
                    className={[
                      classes.featureParagraph,
                      classes.bodyP,
                      "md:pr-4 pl-3 md:pl-0",
                    ].join(" ")}
                  >
                    We are a members of over 20 different MLS associations in
                    California.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2">
              <div className="flex ">
                <div className="w-1/6">
                  <img
                    src={realEstateTechnologyIcon}
                    className={classes.brokerIcon}
                    alt=""
                  />
                </div>
                <div className="w-5/6">
                  <h3
                    className={[
                      classes.featureTitle,
                      "md:text-lg pl-3 text-xs  font-medium pl-3 md:pl-0",
                    ].join(" ")}
                  >
                    PAPERLESS DOCUMENT MANAGEMENT SYSTEM
                  </h3>
                  <p
                    className={[
                      classes.featureParagraph,
                      classes.bodyP,
                      "pl-3 md:pl-0",
                    ].join(" ")}
                  >
                    Manage your transactions on the cloud. Upload, access, share
                    and manage all your files and documents anytime, anywhere
                    with our paperless management system online.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container mt-0 md:mt-3-important">
          <div className="flex md:mb-4 flex-wrap">
            <div className="w-full md:w-1/2 ">
              <div className="flex  mb-12 md:mb-4">
                <div className="w-1/6">
                  <img
                    src={shortSaleProcessorIcon}
                    className={classes.brokerIcon}
                    alt=""
                  />
                </div>
                <div className="w-5/6">
                  <h3
                    className={[
                      classes.featureTitle,
                      "md:text-lg pl-3 text-xs  font-medium pl-3 md:pl-0",
                    ].join(" ")}
                  >
                    IN-HOUSE SHORT SALE PROCESSOR
                  </h3>
                  <p
                    className={[
                      classes.featureParagraph,
                      classes.bodyP,
                      "md:pr-4 pl-3 md:pl-0",
                    ].join(" ")}
                  >
                    Our In-House Short Sale Processor handles all the paperwork
                    and negotiation with the lender.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2">
              <div className="flex">
                <div className="w-1/6">
                  <img
                    src={transactionCoordinatorIcon}
                    className={classes.brokerIcon}
                    alt=""
                  />
                </div>
                <div className="w-5/6 ">
                  <h3
                    className={[
                      classes.featureTitle,
                      "md:text-lg pl-3 text-xs  font-medium pl-3 md:pl-0",
                    ].join(" ")}
                  >
                    IN-HOUSE TRANSACTION COORDINATOR
                  </h3>
                  <p
                    className={[
                      classes.featureParagraph,
                      classes.bodyP,
                      "pl-3 md:pl-0",
                    ].join(" ")}
                  >
                    Our In-House Transaction Coordinator handles all T.C duties
                    and reminds the agents of current and upcoming events.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Collapse>

      <section className="container mt-0-important">
        <div className="flex justify-center flex-wrap md:pb-12">
          <div className="w-full text-center">
            <button
              className="max-w-xs  bg-white hover:bg-gray-100 text-gray-800 py-3 px-24 border border-gray-400 rounded shadow"
              onClick={() => setViewMore(!viewMore)}
            >
              View More
            </button>
          </div>
        </div>
        <div ref={ourTeamRef} />
      </section>

      <div className={classes.sectionColor}>
        <section className="container mt-3-important">
          <div className="flex flex-wrap lg:pt-12 lg:pb-12">
            <div className="w-full md:w-1/2 ">
              <h3 className="text-center md:text-left text-xl md:text-3xl font-medium dark-blue-font mb-8">
                About Our Company
              </h3>
              <p className={[classes.bodyP, "pr-8 hidden md:flex"].join(" ")}>
                Homequest is a real estate brokerage specializing in residential
                real estate sales, leasing, and escrow.
              </p>
              <p className={[classes.bodyP, "pr-8 hidden md:flex"].join(" ")}>
                We are a friendly team of real estate professionals,
                technologists, and brokers who work together to ensure that you
                receive the best service that you require at a time that suits
                you.
              </p>
              <p className={[classes.bodyP, "pr-8 hidden md:flex"].join(" ")}>
                {" "}
                We know how challenging it is to be a successful real estate
                agent, especially when there are many options for clients to
                choose from when selling or buying there next property. The real
                benefit of joining a flat fee brokerage like Homequest is the
                ability to negotiate your commission.
              </p>
            </div>
            <div className="w-full md:w-1/2 mt-auto mb-auto">
              <img
                src={aboutOurCompanyImage}
                alt=""
                className={[classes.aboutCompanyImage, "shadow-md"].join(" ")}
              />
              <p
                className={[
                  classes.bodyP,
                  classes.aboutSectionP,
                  " flex md:hidden pt-4 ",
                ].join(" ")}
              >
                Homequest is a real estate brokerage specializing in residential
                real estate sales, leasing, and escrow.{" "}
              </p>
              <p
                className={[
                  classes.bodyP,
                  classes.aboutSectionP,
                  "  flex md:hidden pt-4 ",
                ].join(" ")}
              >
                We are a friendly team of real estate professionals,
                technologists, and brokers who work together to ensure that you
                receive the best service that you require at a time that suits
                you.{" "}
              </p>
              <p
                className={[
                  classes.bodyP,
                  classes.aboutSectionP,
                  " flex md:hidden pt-4 ",
                ].join(" ")}
              >
                We know how challenging it is to be a successful real estate
                agent, especially when there are many options for clients to
                choose from when selling or buying there next property. The real
                benefit of joining a flat fee brokerage like Homequest is the
                ability to negotiate your commission.
              </p>
            </div>
          </div>
        </section>
      </div>
      <section className="container mt-3-important">
        <div className="flex mb-4 flex-wrap lg:pt-12 lg:pb-12">
          <div className="w-full md:w-1/2 mt-auto mb-auto md:pr-8 ">
            <h3 className="md:text-left text-center  text-xl md:text-3xl  font-medium dark-blue-font md:hidden mb-8">
              How Homequest Works
            </h3>
            <img
              src={flatFeeRealEstateBroker}
              alt=""
              className={[
                classes.aboutCompanyImage,
                "shadow-md hidden md:flex",
              ].join(" ")}
            />
          </div>
          <div className="w-full md:w-1/2 ">
            <h3 className="text-center md:text-left text-3xl hidden md:flex font-medium dark-blue-font mb-8 mt-8 lg:mt-0">
              How Homequest Works
            </h3>
            <img
              src={flatFeeRealEstateBroker}
              alt=""
              className={[
                classes.aboutCompanyImage,
                "shadow-md flex md:hidden mb-8",
              ].join(" ")}
            />
            <p
              className={[
                classes.bodyP,
                classes.aboutSectionP,
                "pr-8 mb-8",
              ].join(" ")}
            >
              Real Estate Agent's that join Homequest pay a small flat fee on
              every transaction that is closed. No monthly fees, just a one-time
              payment when you close a deal. <br />
            </p>
            <div className={classes.tabSection}>
              <AppBar position="static" className={classes.noShadow}>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label="simple tabs example"
                  classes={{
                    indicator: classes.indicator,
                  }}
                  variant="fullWidth"
                >
                  <Tab
                    className={classes.tab}
                    label="Homequest"
                    {...a11yProps(0)}
                  />
                  <Tab
                    className={classes.tab}
                    label="Requirements"
                    {...a11yProps(1)}
                  />
                  <Tab
                    className={classes.tab}
                    label="E&amp;O Included"
                    {...a11yProps(2)}
                  />
                </Tabs>
              </AppBar>
              <TabPanel value={tabValue} index={0} className={classes.bodyP}>
                Joining Homequest is easy, your current Broker will certify your
                change of employment on the DRE website, we will notify you and
                then you may begin working under our license. Certification is
                generally completed the same business day.
              </TabPanel>
              <TabPanel value={tabValue} index={1} className={classes.bodyP}>
                To join Homequest Real Estate Agents must have a valid
                California Real Estate Salesperson or Broker’s license. We have
                a small one time Start Up Fee of $100 to join our brokerage.
              </TabPanel>
              <TabPanel value={tabValue} index={2} className={classes.bodyP}>
                If you have a professional services business, having errors and
                omissions insurance coverage can be an integral part of
                protecting your business. We include the E&amp;O Fee with each
                transaction.
              </TabPanel>
            </div>
          </div>
        </div>
        <div ref={feeStructureRef} />
      </section>

      <div className={classes.sectionColor}>
        <section className="container mt-3-important">
          <div className="flex flex-wrap lg:pt-12 lg:pb-12">
            <div className="w-full">
              <h2 className="text-center md:text-3xl  text-xl font-medium dark-blue-font">
                Our Fee Structure
              </h2>
            </div>

            <div className="w-full  mt-8">
              <h4 className="text-base md:text-2xl font-medium dark-blue-font mb-2">
                Residential Sales
              </h4>
              <p className="md:text-base" style={{ color: "#88889b" }}>
                Single Family Residence, Condos, Townhomes, Mobile homes &amp;
                2-4 Units
              </p>
              <Table
                className={[classes.table, "mt-4 "].join(" ")}
                aria-label="simple table"
              >
                <colgroup>
                  <col style={{ width: "35%" }} />
                  <col style={{ width: "65%" }} />
                </colgroup>
                <TableHead>
                  <TableRow>
                    <TableCell classes={{ head: classes.tableHead }}>
                      Sales Price
                    </TableCell>
                    <TableCell
                      classes={{ head: classes.tableHead }}
                      align="left"
                    >
                      Our Fee
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      classes={{ root: classes.tableCell }}
                      component="th"
                      scope="row"
                    >
                      Under $1 million
                    </TableCell>
                    <TableCell
                      align="left"
                      classes={{ root: classes.tableCell }}
                    >
                      $475 Transaction Fee (per transaction, per side)
                      <br />
                      $125 E&amp;O (per transaction, per side)
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      classes={{ root: classes.tableCell }}
                    >
                      $1 Million or more
                    </TableCell>
                    <TableCell
                      align="left"
                      classes={{ root: classes.tableCell }}
                    >
                      5% of total gross commission or $100 per $100,000 of sales
                      price.
                      <br />
                      Whichever is greator, includes E&amp;O Insurance. (per
                      transaction, per side)
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>

            <Collapse in={showFees} className={classes.collapseWidth}>
              <div className="w-full  mt-8">
                <h4 className=" text-base md:text-2xl mb-2  font-medium dark-blue-font ">
                  Commercial Sales
                </h4>
                <p className="md:text-base" style={{ color: "#88889b" }}>
                  Commercial sales, mixed use, land, and income property 5+
                  units
                </p>
                <Table
                  className={[classes.table, "mt-4"].join(" ")}
                  aria-label="simple table"
                >
                  <colgroup>
                    <col style={{ width: "35%" }} />
                    <col style={{ width: "65%" }} />
                  </colgroup>
                  <TableHead>
                    <TableRow>
                      <TableCell classes={{ head: classes.tableHead }}>
                        Sales Price
                      </TableCell>
                      <TableCell
                        align="left"
                        classes={{ head: classes.tableHead }}
                      >
                        Our Fee
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        classes={{ root: classes.tableCell }}
                      >
                        Under $1 million
                      </TableCell>
                      <TableCell
                        align="left"
                        classes={{ root: classes.tableCell }}
                      >
                        $475 Transaction Fee (per transaction, per side)
                        <br />
                        $125 E&amp;O (per transaction, per side)
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        classes={{ root: classes.tableCell }}
                      >
                        $1 Million or more
                      </TableCell>
                      <TableCell
                        align="left"
                        classes={{ root: classes.tableCell }}
                      >
                        5% of total gross commission or $100 per $100,000 of
                        sales price.
                        <br />
                        Whichever is greator, includes E&amp;O Insurance. (per
                        transaction, per side)
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
              <div className="w-full  mt-8">
                <h4 className="text-base md:text-2xl mb-2 font-medium dark-blue-font">
                  Leases
                </h4>
                <p className="md:text-base" style={{ color: "#88889b" }}>
                  Residential &amp; Commercial
                </p>
                <Table
                  className={[classes.table, "mt-4"].join(" ")}
                  aria-label="simple table"
                >
                  <colgroup>
                    <col style={{ width: "35%" }} />
                    <col style={{ width: "65%" }} />
                  </colgroup>
                  <TableHead>
                    <TableRow>
                      <TableCell classes={{ head: classes.tableHead }}>
                        Description
                      </TableCell>
                      <TableCell
                        classes={{ head: classes.tableHead }}
                        align="left"
                      >
                        Our Fee
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        classes={{ root: classes.tableCell }}
                      >
                        Transaction fee
                      </TableCell>
                      <TableCell
                        align="left"
                        classes={{ root: classes.tableCell }}
                      >
                        $150 flat fee or 10% of total commission earned.
                        <br /> whichever is greater (per transaction, per side)
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
              <div className="w-full  mt-8">
                <h4 className="text-base md:text-2xl mb-2 font-medium dark-blue-font">
                  Referrals
                </h4>
                <p className="md:text-base" style={{ color: "#88889b" }}>
                  Residential &amp; Commercial
                </p>
                <Table
                  className={[classes.table, "mt-4"].join(" ")}
                  aria-label="simple table"
                >
                  <colgroup>
                    <col style={{ width: "35%" }} />
                    <col style={{ width: "65%" }} />
                  </colgroup>
                  <TableHead>
                    <TableRow>
                      <TableCell classes={{ head: classes.tableHead }}>
                        Description
                      </TableCell>
                      <TableCell
                        classes={{ head: classes.tableHead }}
                        align="left"
                      >
                        Our Fee
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        classes={{ root: classes.tableCell }}
                      >
                        When referring a client to another brokerage
                      </TableCell>
                      <TableCell
                        align="left"
                        classes={{ root: classes.tableCell }}
                      >
                        $399 flat fee or 5% of total commission earned.
                        <br /> Whichever is greater, excludes new construction.
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
              <div className="w-full  mt-8">
                <h4 className="text-md md:text-2xl mb-2 font-medium dark-blue-font">
                  Additional Fees
                </h4>
                <Table
                  className={[classes.table, "mt-4"].join(" ")}
                  aria-label="simple table"
                >
                  <colgroup>
                    <col style={{ width: "35%" }} />
                    <col style={{ width: "65%" }} />
                  </colgroup>
                  <TableHead>
                    <TableRow>
                      <TableCell classes={{ head: classes.tableHead }}>
                        Description
                      </TableCell>
                      <TableCell
                        classes={{ head: classes.tableHead }}
                        align="left"
                      >
                        Our Fee
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        classes={{ root: classes.tableCell }}
                      >
                        Annual fee
                      </TableCell>
                      <TableCell
                        align="left"
                        classes={{ root: classes.tableCell }}
                      >
                        $100 Annual fee. Due at the time of joining Homequest
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
              <div className="w-full  mt-8">
                <h4 className="text-base md:text-2xl mb-2  font-medium dark-blue-font">
                  Optional Services
                </h4>
                <p className="md:text-base" style={{ color: "#88889b" }}>
                  Optional services are not mandatory
                </p>
                <Table
                  className={[classes.table, "mt-4"].join(" ")}
                  aria-label="simple table"
                >
                  <colgroup>
                    <col style={{ width: "35%" }} />
                    <col style={{ width: "65%" }} />
                  </colgroup>
                  <TableHead>
                    <TableRow>
                      <TableCell classes={{ head: classes.tableHead }}>
                        Services
                      </TableCell>
                      <TableCell
                        classes={{ head: classes.tableHead }}
                        align="left"
                      >
                        Our Fee
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        classes={{ root: classes.tableCell }}
                      >
                        Transaction coordinator
                      </TableCell>
                      <TableCell
                        align="left"
                        classes={{ root: classes.tableCell }}
                      >
                        $500.00 per transaction, per side
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        classes={{ root: classes.tableCell }}
                      >
                        In-House Escrow Services
                      </TableCell>
                      <TableCell
                        align="left"
                        classes={{ root: classes.tableCell }}
                      >
                        Please contact our office for pricing
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        classes={{ root: classes.tableCell }}
                      >
                        Short sale processing
                      </TableCell>
                      <TableCell
                        align="left"
                        classes={{ root: classes.tableCell }}
                      >
                        $795.00 &#40;Non Refundable&#41;
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </Collapse>
            <div className=" w-full flex justify-center flex-wrap lg:mb-12 mt-16">
              <div className="w-full text-center">
                <button
                  className="max-w-xs  bg-white hover:bg-gray-100 text-gray-800 py-3 px-24 border border-gray-400 rounded shadow"
                  onClick={() => setShowFees(!showFees)}
                >
                  {showFees ? "Close" : "Show More"}
                </button>
              </div>
            </div>
          </div>
          <div
            ref={testimonialRef}
            style={{ marginTop: "-50px", paddingBottom: "50px" }}
          />
        </section>
      </div>

      <section
        className={["container mt-3-important", classes.overflowShow].join(" ")}
      >
        <div className={["w-full lg:mt-20 ", classes.parent].join(" ")}>
          <h2 className="text-center text-xl md:text-3xl font-medium dark-blue-font">
            Testimonials
          </h2>
          <div className={classes.quote}>
            <img src={quote} alt="" />
          </div>
        </div>
      </section>

      <section className="container mt-3-important">
        <div className="w-full flex justify-center lg:mb-20">
          <TestimonialSlider />
        </div>
      </section>

      <div className={classes.darkSection}>
        <section className="container mt-3-important">
          <div className="w-full flex flex-col justify-center mt-12 md:mt-20">
            <h2 className="text-center text-3xl font-medium text-white ">
              Get Started Today!
            </h2>
            <p
              className={[
                classes.bodyPFooter,
                "text-white md:text-center",
              ].join(" ")}
            >
              Talk with one of our representatives today to get started, We
              address all your <br className="hidden md:flex" />
              questions and provide you with detailed information about our
              company.
            </p>
          </div>
        </section>
        <section className="container mt-3-important">
          <div className=" w-full flex justify-center flex-wrap">
            <div className="w-full text-center">
              <Link to="/contact">
                <button className="max-w-xs  bg-white hover:bg-gray-100 text-gray-800 py-3 px-24 border border-gray-400 rounded shadow">
                  Get Started
                </button>
              </Link>
            </div>
          </div>
        </section>
        <section className="container mt-3-important">
          <div className=" w-full flex justify-center flex-wrap md:mb-20 mb-0">
            <div className="w-1/2 text-center justify-center flex flex-row">
              <a
                href="https://www.facebook.com/hmquest"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook-square text-white text-3xl md:text-4xl cursor-pointer"></i>
              </a>
              <a
                href="https://www.linkedin.com/in/hmquest"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin-in text-white text-3xl md:text-4xl  ml-16 cursor-pointer"></i>
              </a>
              <a
                href="https://www.instagram.com/homequestrealestate/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-instagram text-white text-3xl md:text-4xl  ml-16 cursor-pointer"></i>
              </a>
              <a
                href="https://goo.gl/maps/BTV5hXMWXtM2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-google text-white text-3xl md:text-4xl  ml-16 cursor-pointer"></i>
              </a>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </Fragment>
  );
};

export default withRouter(Landing);
