import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  footer: {
    borderTop: "1px solid rgba(255, 255, 255, 0.15)",
    backgroundColor: "#35414c",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  }
});

function Footer(props) {
  const classes = useStyles();


  return (
    <footer className={classes.footer}>
      <p className="text-xs md:text-base  text-white pt-4 pb-4 pl-4 pr-2">
        &copy; {new Date().getFullYear()} Homequest Real Estate, Inc. All rights Reserved.
      </p>

      <Link
        className="text-xs md:text-base  text-white pt-4 pb-4 pr-4 "
        to="/privacy-and-gdpr-policy"
      >
        Privacy and<br/> GDPR Policy
      </Link>
    </footer>
  );
}

export default Footer;
