import React from "react";
import { Route, Switch } from "react-router-dom";

import Login from "../auth/Login";
import Alert from "../layout/Alert";
import Dashboard from "../dashboard/Dashboard";
import CreateProfile from "../profile-form/CreateProfile";
import PrivateRoute from "../routing/PrivateRoute";
import EditProfile from "../profile-form/EditProfile";
import AddExperience from "../profile-form/AddExperience";
import AddEducation from "../profile-form/AddEducation";

import Posts from "../posts/Posts";

import NotFound from "../layout/NotFound";
import Post from "../post/Post";

import ResetPassword from "../auth/ResetPassword";

import { connect } from "react-redux";

import Navbar from "../layout/Navbar";

const Routes = ({ auth: { user } }) => {
  return (
    <section className="container">
      <Navbar />
      <Alert />
      <Switch>
        <Route exact path="/login" component={Login} />

        <Route exact path="/reset-password" component={ResetPassword} />

        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/create-profile" component={CreateProfile} />
        <PrivateRoute exact path="/edit-profile" component={EditProfile} />
        <PrivateRoute exact path="/add-experience" component={AddExperience} />
        <PrivateRoute exact path="/add-education" component={AddEducation} />
        <PrivateRoute exact path="/posts" component={Posts} />

        <PrivateRoute exact path="/posts/:id" component={Post} />

        <Route component={NotFound} />
      </Switch>
    </section>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Routes);
