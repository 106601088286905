import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import { getCurrentProfile} from '../../actions/profile';
import { Link } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import { getArticles, deleteArticle } from '../../actions/article';
import { getTestimonials, deleteTestimonial } from '../../actions/testimonial';
import AddBoxIcon from '@material-ui/icons/AddBox';


import Table from './components/Table'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  container: {
    padding: '1rem'
  },
  actionWrapper: {
    display: 'flex',
    marginTop: '1rem'
  },
  flexThree: {
    flex: 4
  },
  flexOne: {
    flex: 1
  }
}));

const ViewAll = ({
  auth: { user },
  getArticles,
  getTestimonials,
  deleteTestimonial,
 
  article: { articles, loading: articlesLoading },
  testimonial: { testimonials, loading: testimonialsLoading }
}) => {
  const classes = useStyles();
  useEffect(() => {
    getArticles();
    getTestimonials();
  }, []);

  useEffect(() => {
    getTestimonials();
  }, []);

  useEffect(() => {
    getArticles();
  }, []);

  // let handleMenuClick = (link) => {
  //   history.push(link);
  // }

  return (
    <Fragment>
      <CssBaseline />
      <Container maxWidth='lg' className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <Paper className={classes.paper}>

            <div className={classes.actionWrapper}>
              <div className={classes.flexThree}>
              <Typography variant='h5' gutterBottom>
                Testimonials
              </Typography>
              </div>
              
              <Link to='/dashboard/add-testimonial' className={classes.link}>
                  <AddBoxIcon/>
              </Link>
             
              </div>

         

              {testimonialsLoading && testimonials === null ? (
                  <Spinner />
                ) : (<Table testimonials={testimonials} deleteTestimonial={deleteTestimonial}/>)}
              
              {/* <List dense={true}>
                {testimonialsLoading && testimonials === null ? (
                  <Spinner />
                ) : (
                  testimonials.map((testimonial, i) => {
                    return (
                      <ListItem
                        key={`${testimonial.name}${i}`}
                        className={classes.liNone}
                      >
                        <ListItemText primary={testimonial.name} />
                        <ListItemSecondaryAction>
                          
                            <Link to={`/dashboard/edit-testimonial/${testimonial._id}`}>
                              <Button>Edit</Button>
                            </Link>
                           
                          <IconButton
                            edge='end'
                            aria-label='delete'
                            onClick={() => deleteTestimonial(testimonial._id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  })
                )}
              </List> */}
       
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};


const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile,
  article: state.article,
  testimonial: state.testimonial,
  deleteAccount: PropTypes.func.isRequired
});

export default connect(mapStateToProps, {
  getCurrentProfile,
  getArticles,
  getTestimonials,
  deleteTestimonial,
  deleteArticle
})(ViewAll);
