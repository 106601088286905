import React, { useState } from "react";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { register } from "../../../../../actions/auth";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(6),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  container: {
    padding: "1rem",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  mb2: {
    marginBottom: "2rem",
  },
  mb1: {
    marginBottom: "1rem",
  },
}));

const UserForm = ({ register, history }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [admin, setAdmin] = useState(false);

  const classes = useStyles();

  let changePageTimer = () => {
    setTimeout(() => {
      history.push("/dashboard/admin");
    }, 1500);
  };

  return (
    <Paper className={classes.paper}>
      <form
        onSubmit={(e) => {
          e.preventDefault();

          //@TODO setup errors if fields are empty

          register({ name, email, admin });
          changePageTimer();
        }}
        className={classes.form}
      >
        <TextField
          name="name"
          cols="30"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          variant="outlined"
          className={classes.mb2}
        />
        <TextField
          name="email"
          cols="30"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          variant="outlined"
          className={classes.mb1}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={admin}
              onChange={(e) => setAdmin(e.target.checked)}
              name="admin"
            />
          }
          className={classes.mb1}
          label="Is Admin?"
        />

        <Button type="submit" variant="outlined">
          Save
        </Button>
      </form>
    </Paper>
  );
};

export default withRouter(connect(null, { register })(UserForm));
