import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addArticle } from "../../actions/article";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(6),
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  container: {
    padding: "1rem"
  },
  form: {
    display: "flex",
    flexDirection: "column"
  },
  mb2: {
    marginBottom: "2rem"
  }
}));

const ArticlesForm = ({ addArticle, history }) => {
  const [text, setText] = useState("");
  const [url, setUrl] = useState("");
  const [title, setTitle] = useState("");
  const [snippet, setSnippet] = useState("");

  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <form
        onSubmit={e => {
          e.preventDefault();

          addArticle({ text, url, title, snippet });

          setText("");
          setUrl("");
          setTitle("");
          setSnippet("");
          history.push("/dashboard");
        }}
        className={classes.form}
      >
        <TextField
          name="title"
          cols="30"
          placeholder="Article Title"
          value={title}
          onChange={e => setTitle(e.target.value)}
          required
          variant="outlined"
          className={classes.mb2}
        />
        <TextField
          name="url"
          cols="30"
          rows="5"
          placeholder="Url"
          value={url}
          onChange={e => setUrl(e.target.value)}
          required
          variant="outlined"
          className={classes.mb2}
        />

        <TextField
          name="snippet"
          cols="30"
          rows="5"
          placeholder="Snippet"
          value={snippet}
          onChange={e => setSnippet(e.target.value)}
          required
          variant="outlined"
          className={classes.mb2}
        />

        <TextField
          name="text"
          cols="30"
          rows="5"
          placeholder="Body"
          value={text}
          multiline
          onChange={e => setText(e.target.value)}
          required
          variant="outlined"
          className={classes.mb2}
        />

        <Button type="submit" variant="outlined">
          Save
        </Button>
      </form>
    </Paper>
  );
};

ArticlesForm.propTypes = {
  addArticle: PropTypes.func.isRequired
};

export default withRouter(connect(null, { addArticle })(ArticlesForm));
