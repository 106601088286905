import React, { Fragment, useEffect } from 'react';

import { connect } from 'react-redux';
import EditTestimonialForm from './EditTestimonialForm';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { getTestimonialById } from '../../actions/testimonial';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  container: {
    padding: '1rem'
  }
}));
const EditTestimonial = ({
  getTestimonialById,
  match,
  testimonial: { testimonial, loading: testimonialsLoading }
}) => {
  const classes = useStyles();
  useEffect(() => {
    getTestimonialById(match.params.id);
  }, [getTestimonialById, match.params.id]);

  return (
    <Fragment>
      <Container maxWidth='lg' className={classes.container}>
        <Grid container spacing={3} alignItems='center' justify='center'>
          <Grid item xs={12} lg={12}>
            {!testimonialsLoading && testimonial ? (
              <EditTestimonialForm
                hydrateTestimonial={testimonial}
                testimonialId={match.params.id}
              />
            ) : (
              <div className={classes.loadingContainer}>
                <CircularProgress color='secondary' />
              </div>
            )}
            <Button>
              <Link style={{ color: 'grey' }} to='/dashboard/view-testimonials'>
                Go Back
              </Link>
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

const mapStateToProps = state => ({ testimonial: state.testimonial });

export default connect(mapStateToProps, { getTestimonialById })(
  EditTestimonial
);
