import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import { Link, withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import AddBoxIcon from "@material-ui/icons/AddBox";

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(1),
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },
  link: {
    "&:hover": {
      textDecoration: "none",
    },
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  buttonContainer: {
    color: "var(--primary-color)",
    cursor: "pointer",
    "&hover": {
      color: "#444444",
    },
  },
}));

const UserTable = ({ users, history }) => {
  const classes = useStyles();

  const handleClickOpen = (id) => {
    history.push(`/dashboard/admin/user/edit/${id}`);
  };

  return (
    <>
      <div className={classes.header}>
        <Typography variant="h5" gutterBottom>
          Users
        </Typography>

        <Link to="/dashboard/admin/user/create" className={classes.link}>
          <AddBoxIcon />
        </Link>
      </div>
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="left">email</TableCell>
              <TableCell align="left">admin</TableCell>
              <TableCell align="left">Create Date</TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user._id}>
                <TableCell align="left">{user.name}</TableCell>
                <TableCell align="left">{user.email}</TableCell>
                <TableCell align="left">
                  {user.admin ? "true" : "false"}
                </TableCell>
                <TableCell align="left">
                  {moment(user.date).format("MMM Do YYYY")}{" "}
                </TableCell>
                <TableCell
                  className={classes.buttonContainer}
                  onClick={() => handleClickOpen(user._id)}
                >
                  Edit
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};

export default withRouter(UserTable);
