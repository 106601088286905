import axios from 'axios';
import { setAlert } from './alert';
import {
  GET_ARTICLE,
  GET_ARTICLES,
  ADD_ARTICLE,
  DELETE_ARTICLE,
  EDIT_ARTICLE,
  ARTICLE_ERROR
} from './types';

// Get articles
export const getArticles = () => async dispatch => {
  try {
    const res = await axios.get('/api/articles');
    dispatch({
      type: GET_ARTICLES,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: ARTICLE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get article by id
export const getArticleById = id => async dispatch => {
  try {
    const res = await axios.get(`/api/articles/${id}`);
    dispatch({
      type: GET_ARTICLE,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: ARTICLE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get article by slug
export const getArticleBySlug = slug => async dispatch => {
  try {
    const res = await axios.get(`/api/articles/slug/${slug}`);
    dispatch({
      type: GET_ARTICLE,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: ARTICLE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

export const deleteArticle = id => async dispatch => {
  try {
    await axios.delete(`/api/articles/${id}`);
    dispatch({
      type: DELETE_ARTICLE,
      payload: id
    });
    dispatch(setAlert('Article Removed', 'success'));
  } catch (err) {
    dispatch({
      type: ARTICLE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Add article
export const addArticle = formData => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  try {
    const res = await axios.post(`/api/articles`, formData, config);
    dispatch({
      type: ADD_ARTICLE,
      payload: res.data
    });
    dispatch(setAlert('Article Created', 'success'));
  } catch (err) {
    dispatch({
      type: ARTICLE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

export const EditArticle = (formData, id) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  try {
    const res = await axios.patch(`/api/articles/${id}`, formData, config);
    dispatch({
      type: EDIT_ARTICLE,
      payload: res.data
    });
    dispatch(setAlert('Article Updated', 'success'));
  } catch (err) {
    dispatch({
      type: ARTICLE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
