import React, { Fragment, useState } from 'react';

import { connect } from 'react-redux';
import TestimonialForm from './TestimonialForm';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  container: {
    padding: '1rem'
  }
}));
const Testimonials = () => {
  const classes = useStyles();
  const [formStep, setFormStep] = useState(1);

  return (
    <Fragment>
      <Container maxWidth='lg' className={classes.container}>
        <Grid container spacing={3} alignItems='center' justify='center'>
          <Grid item xs={12} lg={12}>
            <TestimonialForm formStep={formStep} setFormStep={setFormStep} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: '.5rem'
              }}
            >
              <Button>
                <Link style={{ color: 'grey' }} to='/dashboard/view-testimonials'>
                  Go Back
                </Link>
              </Button>
             
            </div>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(Testimonials);
