import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  close: {
    padding: theme.spacing(0.5)
  }
}));

let Alert = ({ alerts }) => {
  const classes = useStyles();

  return (
    <>
      {alerts !== null &&
        alerts.length > 0 &&
        alerts.map((alert, i) => (
          <Snackbar
              key={i}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              open={true}
              autoHideDuration={6000}
              ContentProps={{
                'aria-describedby': 'message-id'
              }}
              message={<span id='message-id'>{alert.msg}</span>}
            />
        ))}
    </>
  );
};

Alert.propTypes = {
  alerts: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  alerts: state.alert
});

export default connect(mapStateToProps)(Alert);
