import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  EditTestimonial,
  addTestimonialImage
} from '../../actions/testimonial';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  container: {
    padding: '1rem'
  },
  form: {
    display: 'flex',
    flexDirection: 'column'
  },
  mb2: {
    marginBottom: '2rem'
  }
}));

const EditTestimonialForm = ({
  EditTestimonial,
  hydrateTestimonial,
  history,
  testimonialId
}) => {
  const [testimonial, setTestimonial] = useState(
    hydrateTestimonial.testimonial
  );
  const [name, setName] = useState(hydrateTestimonial.name);
  const [agentImage, setAgentImage] = useState();
  const [file, setFile] = useState();

  const classes = useStyles();

  let onChange = e => {
    setFile(e.target.files[0]);
  };

  let onFormSubmit = e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('image', file);
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    axios
      .post('/api/testimonials/image', formData, config)
      .then(response => {
        setAgentImage(response.data);
      })
      .catch(error => {});
  };

  return (
    <Paper className={classes.paper}>
      <form
        onSubmit={e => onFormSubmit(e)}
        className={[classes.form, 'mb-6'].join(' ')}
      
      >
        <Button variant='contained' component='label'>
          Select File
          <input
            type='file'
            style={{ display: 'none' }}
            onChange={e => onChange(e)}
          />
        </Button>
        {file && (
          <Button disabled={!file} className='mt-2' type='submit'>
          Upload New Image
        </Button>
        )}
        
      </form>

      <form
        onSubmit={e => {
          e.preventDefault();

          if (agentImage) {
            let image_url = agentImage.url;
            let image_id = agentImage.id;
            EditTestimonial(
              { testimonial, name, image_url, image_id },
              testimonialId
            );
          } else {
            EditTestimonial({ testimonial, name }, testimonialId);
          }

          setTestimonial('');
          setName('');
          setAgentImage();
          history.push('/dashboard');
        }}
        className={classes.form}
      >
        <TextField
          name='text'
          cols='30'
          rows='5'
          placeholder='Name'
          value={name}
          onChange={e => setName(e.target.value)}
          required
          variant='outlined'
          className={classes.mb2}
        />

        <TextField
          name='text'
          cols='30'
          rows='5'
          placeholder='Testimonial'
          value={testimonial}
          multiline
          onChange={e => setTestimonial(e.target.value)}
          required
          variant='outlined'
          className={classes.mb2}
        />

        <Button type='submit' variant='outlined'>
          Save
        </Button>
      </form>
    </Paper>
  );
};

EditTestimonialForm.propTypes = {
  EditTestimonial: PropTypes.func.isRequired,
  addTestimonialImage: PropTypes.func.isRequired
};

export default withRouter(
  connect(null, { EditTestimonial, addTestimonialImage })(EditTestimonialForm)
);
