import React, { useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import { getCurrentProfile, deleteAccount } from "../../actions/profile";
import { Link } from "react-router-dom";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import DashboardActions from "../dashboard/DashboardActions";
import Button from "@material-ui/core/Button";
import Experience from "../dashboard/Experience";
import Education from "../dashboard/Education";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { makeStyles } from "@material-ui/core/styles";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Paper from "@material-ui/core/Paper";
import { getArticles, deleteArticle } from "../../actions/article";
import { getTestimonials, deleteTestimonial } from "../../actions/testimonial";
import AddBoxIcon from "@material-ui/icons/AddBox";
import Table from "./components/Table";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  container: {
    padding: "1rem",
  },
  link: {
    "&:hover": {
      textDecoration: "none",
    },
  },
  actionWrapper: {
    display: "flex",
    marginTop: "1rem",
  },
  flexThree: {
    flex: 4,
  },
  flexOne: {
    flex: 1,
  },
}));

const ViewAll = ({
  auth: { user },
  getArticles,
  getTestimonials,
  deleteTestimonial,
  deleteArticle,
  article: { articles, loading: articlesLoading },
  testimonial: { testimonials, loading: testimonialsLoading },
}) => {
  const classes = useStyles();
  useEffect(() => {
    getArticles();
    getTestimonials();
  }, []);

  useEffect(() => {
    getTestimonials();
  }, []);

  useEffect(() => {
    getArticles();
  }, []);

  return (
    <Fragment>
      <CssBaseline />
      <Container maxWidth="xl" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12} xl={12}>
            <Paper className={classes.paper}>
              <div className={classes.actionWrapper}>
                <div className={classes.flexThree}>
                  <Typography variant="h5" gutterBottom>
                    Articles
                  </Typography>
                </div>

                <Link to="/dashboard/add-article" className={classes.link}>
                  <AddBoxIcon />
                </Link>
              </div>

              {articlesLoading && articles === null ? (
                <Spinner />
              ) : (
                <Table articles={articles} deleteArticle={deleteArticle} />
              )}

              {/* <List dense={true}>
                {articlesLoading && articles === null ? (
                  <Spinner />
                ) : (
                  articles.map((article, i) => {
                    return (
                      <ListItem key={`${article.title}${i}`}>
                        <ListItemText primary={article.title} />
                        <ListItemSecondaryAction>
                          
                            <Link to={`/dashboard/edit-article/${article._id}`}>
                              <Button>Edit</Button>
                            </Link>
                          
                          <IconButton
                            edge='end'
                            aria-label='delete'
                            onClick={() => deleteArticle(article._id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  })
                )}
              </List> */}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
  article: state.article,
  testimonial: state.testimonial,
  deleteAccount: PropTypes.func.isRequired,
});

export default connect(mapStateToProps, {
  getCurrentProfile,
  getArticles,
  getTestimonials,
  deleteTestimonial,
  deleteArticle,
})(ViewAll);
