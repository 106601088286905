import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { EditArticle } from '../../actions/article';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(6),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  container: {
    padding: '1rem'
  },
  form: {
    display: 'flex',
    flexDirection: 'column'
  },
  mb2: {
    marginBottom: '2rem'
  }
}));

const EditArticleForm = ({ EditArticle, article, history, articleId }) => {
  const [text, setText] = useState(article.text);
  const [url, setUrl] = useState(article.url);
  const [title, setTitle] = useState(article.title);
  const [snippet, setSnippet] = useState(article.snippet);

  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <form
        onSubmit={e => {
          e.preventDefault();

          EditArticle({ text, url, title, snippet }, articleId);

          setText('');
          setUrl('');
          setTitle('');
          setSnippet('');
          history.push('/dashboard');
        }}
        className={classes.form}
      >
        <TextField
          name='title'
          cols='30'
          placeholder='Article Title'
          value={title}
          onChange={e => setTitle(e.target.value)}
          required
          variant='outlined'
          className={classes.mb2}
        />
        <TextField
          name='url'
          cols='30'
          rows='5'
          placeholder='Url'
          value={url}
          onChange={e => setUrl(e.target.value)}
          required
          variant='outlined'
          className={classes.mb2}
        />

        <TextField
          name='snippet'
          cols='30'
          rows='5'
          placeholder='Snippet'
          value={snippet}
          onChange={e => setSnippet(e.target.value)}
          required
          variant='outlined'
          className={classes.mb2}
        />

        <TextField
          name='text'
          cols='30'
          rows='5'
          placeholder='Testimonial'
          value={text}
          multiline
          onChange={e => setText(e.target.value)}
          required
          variant='outlined'
          className={classes.mb2}
        />

        <Button type='submit' variant='outlined'>
          Save
        </Button>
      </form>
    </Paper>
  );
};

EditArticleForm.propTypes = {
  Editrticle: PropTypes.func.isRequired
};

export default withRouter(connect(null, { EditArticle })(EditArticleForm));
