import React, { Fragment } from "react";

import { connect } from "react-redux";
import UserForm from "../UserForm";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  container: {
    padding: "1rem",
  },
}));

const AddUser = () => {
  const classes = useStyles();

  return (
    <Fragment>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3} alignItems="center" justify="center">
          <Grid item xs={12} lg={12}>
            <UserForm />
            <Button style={{ marginTop: "1rem" }}>
              <Link style={{ color: "grey" }} to="/dashboard/admin">
                Go Back
              </Link>
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(AddUser);
