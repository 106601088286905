import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import bannerImg from "../../assets/img/banner.jpg";
import contactImg from "../../assets/img/contact-us-background.jpg";
import theme from "../../components/layout/theme";
import Paper from "@material-ui/core/Paper";

import Footer from "../layout/Footer";
import PublicNavbar from "../layout/PublicNavbar";

const useStyles = makeStyles({
  bannerImg: {
    background: `url(${bannerImg}) bottom center`,
    backgroundPosition: "50% 50%",
    backgroundSize: "cover",
    backgroundColor: "#3498db",
    transform: "none",
    height: "100%",
    position: "absolute",
    width: "100%",
  },
  contactSection: {
    background: `url(${contactImg}) bottom center`,
    backgroundPosition: "50% 50%",
    backgroundSize: "cover",
    backgroundColor: "#3498db",
    transform: "none",
    height: "50%",
    position: "absolute",
    width: "100%",
    opacity: 0.4,
  },
  banner: {
    height: "500px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      height: "400px",
    },
  },
  containerBanner: {
    paddingTop: "14rem",
    marginTop: "0px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "8rem",
    },
  },

  articleP: {
    color: "#88889b",
    fontSize: "15px",
    lineHeight: "28px",
    marginTop: "12px",
  },
  articleH: {
    color: "#565659",
    fontSize: "17px",
    lineHeight: "28px",
    marginTop: "12px",
  },
  articleH2: {
    color: "#565659",
    fontSize: "15px",
    lineHeight: "28px",
    marginTop: "12px",
  },
  articleDate: {
    color: "#88889b",
    fontSize: ".8rem",
    lineHeight: "28px",
  },
  bodyPFooter: {
    color: "white",
    fontSize: "15px",
    lineHeight: "28px",
    marginTop: "20px",
  },
  darkSection: {
    backgroundColor: "#35414c",
  },
  footer: {
    borderTop: "1px solid rgba(255, 255, 255, 0.15)",
  },
  contactFormContainer: {
    position: "absolute",
    top: "20%",
    zIndex: 99,
    width: "100%",
  },
  paper: {
    marginTop: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    margin: "auto",
    [theme.breakpoints.down("xs")]: {
      left: "5%",
      width: "97.5%",
      padding: 25,
    },
  },
  bannerZIndex: {
    zIndex: 99,
  },
  bannerText: {
    fontSize: "50px",
    color: "#fff",
    marginBottom: "0px",
    lineHeight: "60px",
    fontWeight: 400,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "35px",
      lineHeight: "45px",
      marginTop: "50px",
    },
  },
});

const GdprPolicy = ({ isAuthenticated }) => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <PublicNavbar />
      <section className={classes.banner}>
        <div className={classes.bannerImg}></div>
        <div
          className={["container mx-auto", classes.containerBanner].join(" ")}
        >
          <div className="flex mb-4">
            <div
              className={[
                "w-full justify-center items-center flex-col flex",
                classes.bannerZIndex,
              ].join(" ")}
            >
              <h1 className={classes.bannerText}>Privacy / GDPR Policy</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="container mt-3-important">
        <div className="w-full flex justify-center mb-20">
          <Paper className={classes.paper}>
            <p className={classes.articleDate}>Last updated: 04-02-2020</p>
            <p className={classes.articleP}>
              Thank you for choosing to be part of our community at Homequest
              Real Estate, Inc (“Company”, “we”, “us”, or “our”). We are
              committed to protecting your personal information and your right
              to privacy. If you have any questions or concerns about our
              notice, or our practices with regards to your personal
              information, please contact us at info@homequest.com.
            </p>
            <p className={classes.articleP}>
              When you visit our website joinhomequest.com, and use our
              services, you trust us with your personal information. We take
              your privacy very seriously. In this privacy notice, we seek to
              explain to you in the clearest way possible what information we
              collect, how we use it and what rights you have in relation to it.
              We hope you take some time to read through it carefully, as it is
              important. If there are any terms in this privacy notice that you
              do not agree with, please discontinue use of our Sites and our
              services.
            </p>
            <p className={classes.articleP}>
              This privacy notice applies to all information collected through
              our website (such as joinhomequest.com), and/or any related
              services, sales, marketing or events (we refer to them
              collectively in this privacy notice as the "Services").
            </p>
            <p className={classes.articleP}>
              Please read this privacy notice carefully as it will help you make
              informed decisions about sharing your personal information with
              us.
            </p>
            <h5 className={classes.articleH}>
              1. WHAT INFORMATION DO WE COLLECT?
            </h5>
            <h5 className={classes.articleH2}>
              Information automatically collected
            </h5>
            <p className={classes.articleP}>
              In Short: Some information — such as IP address and/or browser and
              device characteristics — is collected automatically when you visit
              our Services. We automatically collect certain information when
              you visit, use or navigate the Services. This information does not
              reveal your specific identity (like your name or contact
              information) but may include device and usage information, such as
              your IP address, browser and device characteristics, operating
              system, language preferences, referring URLs, device name,
              country, location, information about how and when you use our
              Services and other technical information. This information is
              primarily needed to maintain the security and operation of our
              Services, and for our internal analytics and reporting purposes.
              Like many businesses, we also collect information through cookies
              and similar technologies.
            </p>
            <h5 className={classes.articleH}>
              2. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
            </h5>

            <p className={classes.articleP}>
              In Short: We only share information with your consent, to comply
              with laws, to provide you with services, to protect your rights,
              or to fulfill business obligations.
            </p>
            <p className={classes.articleP}>
              We may process or share data based on the following legal basis:
            </p>
            <p className={classes.articleP}>
              Consent: We may process your data if you have given us specific
              consent to use your personal information in a specific purpose.
            </p>
            <p className={classes.articleP}>
              Legitimate Interests: We may process your data when it is
              reasonably necessary to achieve our legitimate business interests.
            </p>
            <p className={classes.articleP}>
              Performance of a Contract: Where we have entered into a contract
              with you, we may process your personal information to fulfill the
              terms of our contract.
            </p>
            <p className={classes.articleP}>
              Legal Obligations: We may disclose your information where we are
              legally required to do so in order to comply with applicable law,
              governmental requests, a judicial proceeding, court order, or
              legal process, such as in response to a court order or a subpoena
              (including in response to public authorities to meet national
              security or law enforcement requirements).
            </p>
            <p className={classes.articleP}>
              Vital Interests: We may disclose your information where we believe
              it is necessary to investigate, prevent, or take action regarding
              potential violations of our policies, suspected fraud, situations
              involving potential threats to the safety of any person and
              illegal activities, or as evidence in litigation in which we are
              involved.
            </p>

            <p className={classes.articleP}>
              More specifically, we may need to process your data or share your
              personal information in the following situations:
            </p>
            <p className={classes.articleP}>
              Vendors, Consultants and Other Third-Party Service Providers. We
              may share your data with third party vendors, service providers,
              contractors or agents who perform services for us or on our behalf
              and require access to such information to do that work. Examples
              include: payment processing, data analysis, email delivery,
              hosting services, customer service and marketing efforts. We may
              allow selected third parties to use tracking technology on the
              Services, which will enable them to collect data about how you
              interact with the Services over time. This information may be used
              to, among other things, analyze and track data, determine the
              popularity of certain content and better understand online
              activity. Unless described in this Policy, we do not share, sell,
              rent or trade any of your information with third parties for their
              promotional purposes.
            </p>
            <p className={classes.articleP}>
              Business Transfers. We may share or transfer your information in
              connection with, or during negotiations of, any merger, sale of
              company assets, financing, or acquisition of all or a portion of
              our business to another company.
            </p>
            <p className={classes.articleP}>
              Third-Party Advertisers. We may use third-party advertising
              companies to serve ads when you visit the Services. These
              companies may use information about your visits to our Website(s)
              and other websites that are contained in web cookies and other
              tracking technologies in order to provide advertisements about
              goods and services of interest to you. See our Cookie Policy:
              joinhomequest.com/privacy-policy for further information.
            </p>
            <h5 className={classes.articleH}>
              3. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </h5>
            <p className={classes.articleP}>
              In Short: We may use cookies and other tracking technologies to
              collect and store your information. We may use cookies and similar
              tracking technologies (like web beacons and pixels) to access or
              store information. Specific information about how we use such
              technologies and how you can refuse certain cookies is set out in
              our Cookie Policy: joinhomequest.com/privacy-policy.
            </p>
            <h5 className={classes.articleH}>
              4. HOW LONG DO WE KEEP YOUR INFORMATION?
            </h5>
            <p className={classes.articleP}>
              In Short: We keep your information for as long as necessary to
              fulfill the purposes outlined in this privacy notice unless
              otherwise required by law. We will only keep your personal
              information for as long as it is necessary for the purposes set
              out in this privacy notice, unless a longer retention period is
              required or permitted by law (such as tax, accounting or other
              legal requirements). No purpose in this policy will require us
              keeping your personal information for longer than 2 years. When we
              have no ongoing legitimate business need to process your personal
              information, we will either delete or anonymize it, or, if this is
              not possible (for example, because your personal information has
              been stored in backup archives), then we will securely store your
              personal information and isolate it from any further processing
              until deletion is possible.
            </p>
            <h5 className={classes.articleH}>
              5. HOW DO WE KEEP YOUR INFORMATION SAFE?
            </h5>
            <p className={classes.articleP}>
              In Short: We aim to protect your personal information through a
              system of organizational and technical security measures. We have
              implemented appropriate technical and organizational security
              measures designed to protect the security of any personal
              information we process. However, please also remember that we
              cannot guarantee that the internet itself is 100% secure. Although
              we will do our best to protect your personal information,
              transmission of personal information to and from our Services is
              at your own risk. You should only access the services within a
              secure environment.
            </p>
            <h5 className={classes.articleH}>
              6. DO WE COLLECT INFORMATION FROM MINORS?
            </h5>
            <p className={classes.articleP}>
              In Short: We do not knowingly collect data from or market to
              children under 18 years of age. We do not knowingly solicit data
              from or market to children under 18 years of age. By using the
              Services, you represent that you are at least 18 or that you are
              the parent or guardian of such a minor and consent to such minor
              dependent’s use of the Services. If we learn that personal
              information from users less than 18 years of age has been
              collected, we will deactivate the account and take reasonable
              measures to promptly delete such data from our records. If you
              become aware of any data we have collected from children under age
              18, please contact us at info@homequest.com.
            </p>
            <h5 className={classes.articleH}>
              7. WHAT ARE YOUR PRIVACY RIGHTS?
            </h5>
            <p className={classes.articleP}>
              In Short: You may review, change, or terminate your account at any
              time. If you are resident in the European Economic Area and you
              believe we are unlawfully processing your personal information,
              you also have the right to complain to your local data protection
              supervisory authority. You can find their contact details{" "}
              <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
                here
              </a>{" "}
              .
            </p>
            <p className={classes.articleP}>
              Cookies and similar technologies: Most Web browsers are set to
              accept cookies by default. If you prefer, you can usually choose
              to set your browser to remove cookies and to reject cookies. If
              you choose to remove cookies or reject cookies, this could affect
              certain features or services of our Services. To opt-out of
              interest-based advertising by advertisers on our Services visit{" "}
              <a href="http://www.aboutads.info/choices/">aboutads.info</a>.
            </p>
            <h5 className={classes.articleH}>
              8. CONTROLS FOR DO-NOT-TRACK FEATURES
            </h5>
            <p className={classes.articleP}>
              Most web browsers and some mobile operating systems and mobile
              applications include a Do-Not-Track (“DNT”) feature or setting you
              can activate to signal your privacy preference not to have data
              about your online browsing activities monitored and collected. No
              uniform technology standard for recognizing and implementing DNT
              signals has been finalized. As such, we do not currently respond
              to DNT browser signals or any other mechanism that automatically
              communicates your choice not to be tracked online. If a standard
              for online tracking is adopted that we must follow in the future,
              we will inform you about that practice in a revised version of
              this privacy notice.
            </p>
            <h5 className={classes.articleH}>
              9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </h5>
            <p className={classes.articleP}>
              In Short: Yes, if you are a resident of California, you are
              granted specific rights regarding access to your personal
              information.
            </p>
            <p className={classes.articleP}>
              California Civil Code Section 1798.83, also known as the “Shine
              The Light” law, permits our users who are California residents to
              request and obtain from us, once a year and free of charge,
              information about categories of personal information (if any) we
              disclosed to third parties for direct marketing purposes and the
              names and addresses of all third parties with which we shared
              personal information in the immediately preceding calendar year.
              If you are a California resident and would like to make such a
              request, please submit your request in writing to us using the
              contact information provided below.
            </p>
            <p className={classes.articleP}>
              If you are under 18 years of age, reside in California, and have a
              registered account with the Services, you have the right to
              request removal of unwanted data that you publicly post on the
              Services. To request removal of such data, please contact us using
              the contact information provided below, and include the email
              address associated with your account and a statement that you
              reside in California. We will make sure the data is not publicly
              displayed on the Services, but please be aware that the data may
              not be completely or comprehensively removed from our systems.
            </p>
            <h5 className={classes.articleH}>
              10. DO WE MAKE UPDATES TO THIS POLICY?
            </h5>
            <p className={classes.articleP}>
              In Short: Yes, we will update this policy as necessary to stay
              compliant with relevant laws.
            </p>

            <p className={classes.articleP}>
              We may update this privacy notice from time to time. The updated
              version will be indicated by an updated “Revised” date and the
              updated version will be effective as soon as it is accessible. If
              we make material changes to this privacy notice, we may notify you
              either by prominently posting a notice of such changes or by
              directly sending you a notification. We encourage you to review
              this privacy notice frequently to be informed of how we are
              protecting your information.
            </p>
            <h5 className={classes.articleH}>
              11. HOW CAN YOU CONTACT US ABOUT THIS POLICY?
            </h5>
            <p className={classes.articleP}>
              If you have questions or comments about this policy, you may email
              us at info@homequest.com or by post to: Homequest Real Estate, Inc
              4772 Pipeline Ave STE C, Chino Hills, CA 91709 United States
            </p>
            <h5 className={classes.articleH}>
              HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </h5>
            <p className={classes.articleP}>
              Based on the laws of some countries, you may have the right to
              request access to the personal information we collect from you,
              change that information, or delete it in some circumstances. To
              request to review, update, or delete your personal information,
              please visit: info@homequest.com. We will respond to your request
              within 30 days.
            </p>
          </Paper>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(GdprPolicy);
