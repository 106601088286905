import React, { Fragment, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Landing from "./components/layout/Landing";
import Contact from "./components/public/Contact";
import Articles from "./components/public/Articles";
import ViewArticle from "./components/public/ViewArticle";
import Routes from "./components/routing/Routes";
import GdprPolicy from "./components/public/GdprPolicy";
// Redux
import { Provider } from "react-redux";
import store from "./store";

import { loadUser } from "./actions/auth";
import setAuthToken from "./utils/setAuthToken";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);
  return (
    <Provider store={store}>
      <Router>
        <Fragment>
     
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route exact path="/contact" component={Contact} />
            <Route
              exact
              path="/privacy-and-gdpr-policy"
              component={GdprPolicy}
            />
            <Route exact path="/articles" component={Articles} />
            <Route exact path="/articles/:slug" component={ViewArticle} />
            <Route component={Routes} />
          </Switch>
        </Fragment>
      </Router>
    </Provider>
  );
};

export default App;
