import axios from 'axios';

export const sendEmail = async ({firstName, lastName, phone, email}) => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
  
    const body = JSON.stringify({ firstName, lastName, phone, email });
  
    try {
      const res = await axios.post('/api/contact', body, config);
     
      if(res.status !== 200) {
        throw new Error('email not sent')
      }
    return res
    } catch (err) {
        console.log(err)
      return 'email not sent'
    }
  };