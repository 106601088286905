import React from "react";

const NotFound = (props) => {
  return (
    <div
      style={{
        height: "70vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div>
        <h1 className=" text-primary text-center">
          <i className="fas fa-exclamation-triangle" /> Page Not Found
        </h1>
        <p className="text-center">Sorry, this page does not exist </p>
      </div>
    </div>
  );
};

export default NotFound;
