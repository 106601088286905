import axios from "axios";
import { GET_USERS, USER_ERROR } from "./types";
import { setAlert } from "./alert";

// Get all users
export const getUsers = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/users");

    dispatch({
      type: GET_USERS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: USER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Delete account
// admin route
export const deleteUser = (userId) => async (dispatch) => {
  try {
    const res = await axios.delete(`/api/users/${userId}`);

    dispatch(setAlert("Account deleted", "success"));
  } catch (err) {
    dispatch({
      type: USER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Register User
export const editUser = ({ name, email, admin, userId }) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ name, email, admin });

  try {
    const res = await axios.put(`/api/users/${userId}`, body, config);

    if (res["data"]["status"] === "error") {
      throw { msg: res["data"]["message"] };
    }

    dispatch(setAlert("User updated", "success"));

    // @Todo dispatch fetch users
  } catch (err) {
    dispatch(setAlert(err.msg, "danger"));
  }
};
