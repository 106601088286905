import React, { useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

import { getUsers } from "../../../actions/user";
import Spinner from "../../layout/Spinner";
import UserTable from "./components/UserTable";
import UserCount from "./components/UserCount";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const Admin = ({ getUsers, users: { users } }) => {
  useEffect(() => {
    getUsers();
  }, [getUsers]);
  const classes = useStyles();

  return (
    <div>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <UserTable users={users} />
          </Grid>
          <Grid item xs={6}>
            {/* <UserCount profiles={profiles} /> */}
          </Grid>
          {/* <Grid item xs={6}>
            <Paper className={classes.paper}>xs=6</Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper className={classes.paper}>xs=3</Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper className={classes.paper}>xs=3</Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper className={classes.paper}>xs=3</Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper className={classes.paper}>xs=3</Paper>
          </Grid> */}
        </Grid>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  users: state.user,
});

export default connect(mapStateToProps, { getUsers })(Admin);
